import React from 'react';



export function PlaceholderDashboard({
    placeholderText
}) {
    return pug `
        .text_disabled-dashboard
            ${placeholderText}
            `
}



export function PlaceholderCryptoWallet({
    handleRequestButtonClick
}) {
    return pug `
    .block_content-CW
        .header_content-elem-dashboard CryptoWallet
        .banner_elem-dashboard
            .title_banner-dashboard Secure multi-sig cryptocurrency wallet website
            .text_banner-dashboard Ready to be up and running after deployment to your Amazon Web Services account #[br] and minimal configuration.
            .link_button_banner(onClick=${handleRequestButtonClick}) Request quote
            `
}



export function PlaceholderAmbisafeVault({
    handleRequestButtonClick,
    me
}) {
    return pug `
    .block_content-AV
        .header_content-elem-dashboard AmbiVault
        .banner_elem-dashboard
            .title_banner-dashboard Multi-sig storage for your cryptocurrency and tokens
            .text_banner-dashboard Every action requires multiply signatures (confirmations) from the parties you trust. This lets you perform asset management actions in the cloud securely.
            .wrapper_banner-info-fee
                .text_fee Monthly fee:&#160;
                    span.price_banner-fee $1000
                    span.text_banner-dashboard &#160;— charged on the 1st day of every month.
                .text_fee Operation fee:&#160;
                    span.price_banner-fee $10
                    span.text_banner-dashboard &#160;— charged at the end of the billing period.
                .wrapper_banner-note-fee
                    .text_fee NOTE:&#160;
                        span.text_banner-dashboard The monthly fee is charged immediately after the registration. The sum is counted considering the days left till the end of the current month.
            .link_button_banner(onClick=${handleRequestButtonClick}) ${me.payment_method_exists ?
                'Sign up for AmbiVault' : 'Add payment method'
            }`
}
