import React from 'react';

import {
    RowTableCryptoWallet
} from './ElemsEnabledDashboard';



export default function TableCryptoWallet({
    items
}) {

    return pug `
    .block_content-CW
        .header_content-elem-dashboard CryptoWallet
        .table_elem-dashboard
            .row_title
                .inner_wrapper_row
                    .cell_period-CW Billing period
                    .cell_users-CW Active users
                    .cell_price-CW Price per user
                    .cell_total-CW Total
                    .cell_status-CW Status
            .wrapper_rows-content
                ${items.map((item, index) =>
                    pug`RowTableCryptoWallet(
                            key=${index}
                            item=${item}
                        )
                    `)
                }
            `
}
