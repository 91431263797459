import React from 'react';
import ReactDOM from 'react-dom';

import classNames from 'classnames';

import {
    getElem
} from '../utils.js';


const ModalWindow = props => {
    const { children, ...rest } = props;
    return (
        <div>
            { props.active &&
                <ModalController {...rest}>
                    {children}
                </ModalController>
            }
        </div>
    );
}

class ModalController extends React.Component {
    constructor(props) {
        super(props);

        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);        
    }

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('click', this.handleOutsideClick, false);
        const savedScrollPosition = window.pageYOffset,
            mainElem = getElem('body')[0];

        mainElem.classList.add('is-fixed');

        mainElem.style.top = -savedScrollPosition + 'px';

        this.setState({
            savedScrollPosition: savedScrollPosition
        });
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp, false);
        document.removeEventListener('click', this.handleOutsideClick, false);
        getElem('body')[0].classList.remove('is-fixed');

        window.scrollTo(0, this.state.savedScrollPosition);
    }

    handleKeyUp(e) {
        const { closeModal } = this.props;

        const keys = {
            // 27 is default code for ESC key
            27: () => {
                e.preventDefault();
                window.removeEventListener('keyup', this.handleKeyUp, false);
                closeModal();
            },
        };

        if (keys[e.keyCode]) { keys[e.keyCode](); }
    }

    handleOutsideClick(e) {
        const { closeModal } = this.props;

        if (!(this.modal === undefined)) {
            if (!this.modal.contains(e.target)) {
                e.preventDefault();
                closeModal();
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
        }
    }

    render() {
        return pug`
            .modal_window.elem_modal.is-visible(class=${classNames(this.props.modalClass)})
                .wrapper_modal-content(ref=${modal => this.modal = modal})
                    .bttn_close-modal(onClick=${this.props.closeModal})
                    .block_content-modal
                         ${this.props.children}
        `
    }
}

export default ModalWindow;
