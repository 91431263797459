import React from 'react';

import {
    Formik
} from 'formik';

import axios from 'axios';

import endpoints from '../endpoints.js';

import classNames from 'classnames/dedupe';

import zxcvbn from 'zxcvbn';

import {
    valEqual,
    valRequired,
    valMinLength
} from '../utils.js';

import {
    globalErrorMessages,
    fieldsErrorMessages,
    autoHideNotifications,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData
} from '../notifications.js';


import {
    Link,
    withRouter
} from 'react-router-dom';

import routes from '../routes.js';

import FormInput from './../common/FormElems.js';
import GlobalErrorNotification from '../common/GlobalErrorNotification.js';

import {
    Footer
} from './Footer';

const formData = {
    password: {
        inputClassName: 'field_password',
        type: 'password',
        label: 'New password'
    },
    confirm_password: {
        inputClassName: 'field_password',
        type: 'password',
        label: 'Confirm new password'
    }
};

const FormRestorePassPassword = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    zxcvbnScore,
    isSubmitting,
}) => {

    return pug `
    form.form_restore-pass(onSubmit=${handleSubmit} noValidate autoComplete="off")
        FormInput(
            item=${formData['password']}
            name=${'password'}
            value=${values['password']}
            inputClass=${touched['password'] && errors['password'] && 'is-invalid'}
            errorDescription=${touched['password'] && errors['password']}
            handleChange=${handleChange}
            handleBlur=${handleBlur}
        )
        .wrapper_decor-pass-strength(
            class=${classNames(
                {'is-simple': (zxcvbnScore !== false && zxcvbnScore < 2)},
                {'is-normal': (zxcvbnScore == 2 || zxcvbnScore == 3)},
                {'is-strong': (zxcvbnScore == 4)}
            )}
        )
            .decor-pass-strength
            .decor-pass-strength
            .decor-pass-strength
        FormInput(
            item=${formData['confirm_password']}
            name=${'confirm_password'}
            value=${values['confirm_password']}
            inputClass=${touched['confirm_password'] && errors['confirm_password'] && 'is-invalid'}
            errorDescription=${touched['confirm_password'] && errors['confirm_password']}
            handleChange=${handleChange}
            handleBlur=${handleBlur}
        )
        button.bttn_submit(
            type="submit"            
            disabled=${isSubmitting}
            class=${isSubmitting && 'is-disabled'}
        ) Save new password and login
        `
}


class RestorePass2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zxcvbnScore: false,
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            globalErrorText: '',
        }

        this.validate = this.validate.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        let token = this.props.location.pathname.split('/');

        (token.length > 2 && token[2].length) ? (token = token[2]) : (token = false)

        this.setState({
            token: token
        })
    }

    validate(values) {
        let zxcvbnScore = this.state.zxcvbnScore;

        const errors = {};

        Object.keys(values).forEach(key => {
            if (key == 'password') {

                zxcvbnScore = zxcvbn(values[key]).score;

                if (valRequired(values[key])) {
                    zxcvbnScore > 1 ?
                        (delete errors[key]) :
                        (errors[key] = fieldsErrorMessages.password)
                } else {
                    errors[key] = fieldsErrorMessages.require;
                    zxcvbnScore = false;
                }

                valEqual(values['confirm_password'], values[key]) ?
                    (delete errors['confirm_password']) :
                    (errors['confirm_password'] = fieldsErrorMessages.confirmPassword);

            } else if (key == 'confirm_password') {

                valRequired(values[key]) ? (
                    valEqual(values[key], values['password']) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.confirmPassword)
                ) : (errors[key] = fieldsErrorMessages.require);
            }
        });

        this.setState({
            zxcvbnScore: zxcvbnScore,
        });

        return errors
    }

    handleSubmit(values, formikBag) {
        const valuesToSend = Object.assign({}, {
            token: this.state.token
        }, values);

        axios.post(endpoints.passwordResetConfirm, valuesToSend)
            .then(response => {
                localStorage.setItem('successfulPasswordChange', true);
                this.props.history.push(routes.login);
            })
            .catch(error => {
                formikBag.setSubmitting(false);

                this.handleErrorsWhileSendingData(error, formikBag)
            });
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    render() {
        const {
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalErrorText,
        } = this.state;

        return pug `
.wrapper_page.wrapper_page_with_footer
    ${isGlobalErrorVisible &&
        pug`
            GlobalErrorNotification(
                notificationText=${globalErrorText}
                isVisible=${isGlobalErrorAnimated}
                handleErrorNotificationClose=${this.handleErrorNotificationClose}
            )
        `
    }
    header.header_main
        a(href="/").img_logo
            img(src="images/Ambisafe_logo_dark.svg" alt="logo")
        h1.title_block Set new password and login
    .wrapper_forms-new-pass

        Formik(render=${formikProps =>
            pug`
                FormRestorePassPassword(
                    ...formikProps
                    ...this.props
                    zxcvbnScore=${this.state.zxcvbnScore}
                )`
            }
            validate=${this.validate}
            onSubmit=${this.handleSubmit}
            initialValues=${{password: '', confirm_password: ''}}
        )
    Footer
`
    }
};



export default withRouter(RestorePass2);
