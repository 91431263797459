import React from 'react';

function MainPageWrapper({
    children,
    preloaderClassName
}) {

    return pug `
                .wrapper_content
                    .preloader.preloader_inner-page(class=${preloaderClassName})
                    ${children}
                `
}

export default MainPageWrapper;
