export const registrationData_1 = {
    first_name: {
        type: 'text',
        label: 'First name'
    },
    last_name: {
        type: 'text',
        label: 'Last name'
    },
    email: {
        type: 'email',
        label: 'Email'
    },
    password: {
        inputClassName: 'field_password',
        type: "password",
        label: "Password"
    },
    confirm_password: {
        inputClassName: 'field_password',
        type: "password",
        label: "Confirm password",
    }
};
export const registrationData_2 = {
    company: {
        type: 'text',
        label: 'Company'
    },
    title: {
        type: 'text',
        label: 'Position/Title'
    },
    website: {
        type: 'text',
        label: 'Company website',
    }
};
export const registrationData_3 = {
    state: {
        type: 'text',
        label: 'State'
    },
    city: {
        type: 'text',
        label: 'City'
    },
    phone: {
        type: 'text',
        label: 'Phone'
    }
};

export const companySizeOptions = [{
        label: '1-10 employees',
        value: '0',
    },
    {
        label: '11-50 employees',
        value: '1',
    },
    {
        label: '51-200 employees',
        value: '2',
    },
    {
        label: '201-500 employees',
        value: '3',
    },
    {
        label: '501-1000 employees',
        value: '4',
    },
    {
        label: '1001-5000 employees',
        value: '5',
    },
    {
        label: '5001-10,000 employees',
        value: '6',
    },
    {
        label: '10,001+ employees',
        value: '7',
    },
];
