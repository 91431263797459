import React from 'react';

import routes from '../routes.js';

import {
    Route,
    Link,
    NavLink,
    Switch,
    withRouter
} from 'react-router-dom';

import axios from "axios";

import endpoints from '../endpoints.js';

import {
    userAuth
} from '../authorization.js';

import {
    globalErrorMessages,
    autoHideNotifications,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData
} from '../notifications.js';

import {
    showPreloader,
    hidePreloader
} from '../utils.js';

import GlobalErrorNotification from '../common/GlobalErrorNotification';


import PageDashboard from '../p_dashboard/PageDashboard';

import PaymentMethod from '../p_card/PaymentMethod';

import PageHistory from '../p_history/PageHistory';

import PageProfile from '../p_profile/PageProfile';

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            globalErrorText: '',
        }

        this.redirectToAmbiVault = this.redirectToAmbiVault.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);

        this.navItems = [{
                text: 'Dashboard',
                icon: 'dashboard',
                link: routes.dashboard,
                exact: true,
            }, {
                text: 'AmbiVault',
                icon: 'ambiVault',
            }, {
                text: 'Payment method',
                icon: 'cards',
                link: routes.paymentMethods
            },
            /*{
                           text: 'Payment history',
                           icon: 'history',
                           link: routes.paymentHistory
                       },*/
            {
                text: 'My profile',
                icon: 'profile',
                link: routes.profile
            }
        ];
    }

    redirectToAmbiVault() {

        let errorMessage;

        showPreloader('.preloader_inner-page');

        axios.get(endpoints.menuAmbiVault)
            .then(response => {
                self.location = response.data.redirect_url;
            }).catch((error) => {
                if (error.response) {
                    this.handleErrorsWhileSendingData(error, undefined, () => error.response.data.detail);
                } else {
                    this.handleErrorsWhileSendingData(error);
                }
                hidePreloader('.preloader_inner-page');
            });
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    handleSignOut() {
        userAuth.signout(() => {
            this.props.history.push('/');
        });
    }

    render() {
        const {
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalErrorText,
        } = this.state;

        return pug `
            div(data-page=${this.state.activeMenuItem})
                .block_menu-main
                    //.shim
                    .inner_wrapper_menu
                        header.header_menu
                            Link(
                                to=${this.navItems[0].link}
                            )
                                .img_wrapper_logo-main
                                    img(src="/images/Ambisafe_logo_white.svg" alt="logo")
                        nav
                            ul.wrapper_menu-items
                                li.item_menu(
                                    data-menu-item=${this.navItems[0].icon}
                                )
                                    NavLink(
                                        to=${this.navItems[0].link}
                                        activeClassName="is-active"
                                        exact=${ this.navItems[0].exact ? true : false }
                                    )
                                        .item_inner
                                            span ${this.navItems[0].text}
                                li.item_menu(
                                    data-menu-item=${this.navItems[1].icon}
                                    onClick=${this.redirectToAmbiVault}
                                )
                                        .item_inner
                                            span ${this.navItems[1].text}
                                ${ Object.keys(this.navItems).map( index => {
                                    if(index > 1) {
                                        return pug`
                                        li.item_menu(
                                            key=${index}
                                            data-menu-item=${this.navItems[index].icon}
                                        )
                                            NavLink(
                                                to=${this.navItems[index].link}
                                                activeClassName="is-active"
                                                exact=${ this.navItems[index].exact ? true : false }
                                            )
                                                .item_inner
                                                    span ${this.navItems[index].text}
                                    `}
                                })}
                        .item_logout(onClick=${this.handleSignOut})
                            .item_inner
                                span Log out
                main
                    ${isGlobalErrorVisible &&
                        pug`
                            GlobalErrorNotification(
                                notificationText=${globalErrorText}
                                isVisible=${isGlobalErrorAnimated}
                                handleErrorNotificationClose=${this.handleErrorNotificationClose}
                            )
                        `
                    }
                    Switch
                        Route(
                        exact
                        path=${routes.dashboard}
                        component=${PageDashboard}
                        )
                        Route(
                        path=${routes.paymentMethods}
                        component=${PaymentMethod}
                        )
                        Route(
                        path=${routes.paymentHistory}
                        component=${PageHistory}
                        )
                        Route(
                        path=${routes.profile}
                        component=${PageProfile}
                        )
                    `
    }
}

// ${ Object.keys(this.navItems).map( index => pug`
// li.item_menu(
//     key=${index}
//     data-menu-item=${this.navItems[index].icon}
// )
//     NavLink(
//         to=${this.navItems[index].link}
//         activeClassName="is-active"
//         exact=${ this.navItems[index].exact ? true : false }
//     )
//         .item_inner
//             span ${this.navItems[index].text} `
// ) }

export default withRouter(MainMenu);
