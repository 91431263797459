import React from 'react';

export const Footer = () => {

    return pug `
footer.footer_main
    a(
        href=${window.location.origin + '/docs/AmbisafeTerms_Of_Service.pdf'}
        target="_blank"
        class="link-foot"
    )
        span Terms of use
    p.credits © 2018 Ambisafe Inc.
    a(
        href=${window.location.origin + '/docs/AmbisafePrivacy_Policy.pdf'}
        target="_blank"
        class="link-foot"
    )
        span Privacy Policy
    `
};
