import React from 'react';

import {
    RowTableAmbisafeVault
} from './ElemsEnabledDashboard';



export default function TableAmbisafeVault({
    items
}) {

    return pug `
    .block_content-AV
        .header_content-elem-dashboard AmbiVault
        .table_elem-dashboard
            .row_title
                .inner_wrapper_row
                    .cell_period-AV Billing period
                    .cell_operations-AV Operations #[br] processed
                    .cell_price-AV Price per #[br] operation
                    .cell_subscription-AV Subscription
                    .cell_total-AV Total
                    .cell_status-AV Status
            .wrapper_rows-content
                ${items.map(item =>
                    pug`RowTableAmbisafeVault(
                            key=${item.id}
                            item=${item}
                        )
                    `)
                }
            `
}
