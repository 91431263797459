import axios from 'axios';
import endpoints from './endpoints';

export const billing = axios.create({
  baseURL: process.env.API_BILLING_URL,
});

axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('authorizationToken');
billing.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('authorizationToken');

export const userAuth = {
  isAuthenticated: false,
  token: '',
  authenticate(token, cb) {
    this.isAuthenticated = true;
    window.localStorage.setItem('authorizationToken', token);
    this.token = token;
    axios.defaults.headers.common['Authorization'] = 'JWT ' + this.token;
    billing.defaults.headers.common['Authorization'] = 'JWT ' + this.token;
    //axios.defaults.headers.get['Authorization'] = 'JWT ' + this.token;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    this.isAuthenticated = false;
    window.localStorage.clear();
    this.token = '';
    axios.defaults.headers.common['Authorization'] = 'JWT ' + this.token;
    billing.defaults.headers.common['Authorization'] = 'JWT ' + this.token;
    //axios.defaults.headers.get['Authorization'] = 'JWT ' + this.token;
    setTimeout(cb, 100);
  }
};

export const refreshAccessTokenLoop = () => {
    setInterval(() => {
        axios.get(endpoints.refreshAccessToken)
            .then(response => {
                const token = response.data.token;
                window.localStorage.setItem('authorizationToken', token);
                axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
                billing.defaults.headers.common['Authorization'] = 'JWT ' + token;
            })
            .catch(error => false);
    }, 2700000);
}
