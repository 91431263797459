import React from 'react';

import {
  hidePreloader
} from '../utils.js';

import MainPageWrapper from '../common/MainElements';

import FormikProfile from './FormikProfile';



export default function PageProfile() {

    return pug `
MainPageWrapper(
    preloaderClassName=${'preloader_profile'}
)
    header.header_block
        h2.title_block Manage your profile
    .block_content.block_content-profile
        FormikProfile(hidePreloader=${hidePreloader})
            `
}
