import React from 'react';

import {
    withRouter
} from 'react-router';

import axios from 'axios';

import classNames from 'classnames/dedupe';

import {
    globalErrorMessages,
    fieldsErrorMessages,
    autoHideNotifications,
    autoCloseNotificationDuration,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData
} from '../notifications.js';

import endpoints from '../endpoints.js';

import routes from '../routes.js';

import GlobalErrorNotification from '../common/GlobalErrorNotification.js';

import FormikRegistration_1 from './FormikRegistration_1';
import FormikRegistration_2 from './FormikRegistration_2';
import FormikRegistration_3 from './FormikRegistration_3';



class FormRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRegistrationStep: 0,
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            globalErrorText: '',
            keysOfForm_1: [],
            keysOfForm_2: [],
            setFieldError_1: '',
            setFieldError_2: '',
            setFieldError_3: '',
            valuesToSend: {
                userprofile: {}
            },
        }

        this.handleNavClick = this.handleNavClick.bind(this);
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.handleErrorOpen = this.handleErrorOpen.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleNavClick(event, navItemIndex) {
        const {
            currentRegistrationStep,
            validatedSteps,
        } = this.state;


        let stepToSet = currentRegistrationStep;

        if (navItemIndex < currentRegistrationStep) {
            stepToSet = navItemIndex;
        }

        this.setState({
            currentRegistrationStep: stepToSet,
        })
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    handleSubmit(tabNum, values, formikBag) {
        const {
            currentRegistrationStep,
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalErrorText,
            keysOfForm_1,
            keysOfForm_2,
        } = this.state;

        let stepToSet = currentRegistrationStep;

        let errorMessage;

        const valuesToSend = Object.assign({}, this.state.valuesToSend);
        const userprofile = Object.assign({}, this.state.valuesToSend.userprofile);
        valuesToSend.userprofile = userprofile;

        ['first_name', 'last_name', 'company', 'company_size', 'title', 'website', 'country', 'state', 'city', 'phone']
        .forEach(key => {
            if (values[key]) {
                valuesToSend.userprofile[key] = values[key];
            }
        });

        ['email', 'password', 'confirm_password', 'accepted_tos', 'accepted_pp']
        .forEach(key => {
            if (values[key]) {
                valuesToSend[key] = values[key];
            }
        });

        this.setState({
            valuesToSend: valuesToSend,
        });

        if (tabNum === 1) {
            /* going to next step */
            stepToSet++;

            this.setState({
                keysOfForm_1: Object.keys(values),
                currentRegistrationStep: stepToSet,
                setFieldError_1: formikBag.setFieldError,
            })
        } else if (tabNum === 2) {
            /* going to next step */
            stepToSet++;

            this.setState({
                keysOfForm_2: Object.keys(values),
                currentRegistrationStep: stepToSet,
                setFieldError_2: formikBag.setFieldError,
            })
        } else if (tabNum === 3) {


            axios.post(endpoints.signUp, valuesToSend)
                .then(response => {
                    localStorage.setItem('successfulRegistration', true);
                    this.props.history.push(routes.login);
                })
                .catch(error => {
                    formikBag.setSubmitting(false);

                    this.handleErrorsWhileSendingData(error, formikBag, function(setFieldError, stepToSet) {
                        Object.keys(error.response.data).forEach(key => {

                            let errorMessage,
                                setFieldErrorOnCurrentTab = setFieldError;

                            function defineTabWithErrors(key) {
                                keysOfForm_2.forEach(formKey => {
                                    if (key == formKey) {
                                        stepToSet = 1;
                                        setFieldErrorOnCurrentTab = this.state.setFieldError_2;
                                    }
                                });

                                keysOfForm_1.forEach(formKey => {
                                    if (key == formKey) {
                                        stepToSet = 0;
                                        setFieldErrorOnCurrentTab = this.state.setFieldError_1;
                                    }
                                });
                            }

                            if (key == 'userprofile') {

                                Object.keys(error.response.data.userprofile).forEach(userProfileKey => {
                                    defineTabWithErrors.call(this, userProfileKey);
                                    setFieldErrorOnCurrentTab(userProfileKey, error.response.data.userprofile[userProfileKey][0]);
                                });
                            } else {
                                defineTabWithErrors.call(this, key);
                                setFieldErrorOnCurrentTab(key, error.response.data[key][0]);
                            }

                            if (key == 'non_field_errors') {
                                errorMessage = error.response.data.non_field_errors;
                            }
                        });

                        this.setState({
                            currentRegistrationStep: stepToSet,
                        });

                        if (errorMessage) {
                            return errorMessage;
                        }
                    }.bind(this, formikBag.setFieldError, stepToSet))
                });
        }
    }

    handleErrorOpen(selectedCountry, ppCheckboxChecked, tosCheckboxChecked, reCAPTCHAVerified) {
        /* Set global error message text */
        let errorMessage, isGlobalErrorVisible, isGlobalErrorAnimated;

        if (!selectedCountry || !Object.keys(selectedCountry).length) {
            errorMessage = fieldsErrorMessages.country;
            isGlobalErrorVisible = true;
            isGlobalErrorAnimated = true;
        } else if (!ppCheckboxChecked) {
            errorMessage = globalErrorMessages.ppCheckbox;
            isGlobalErrorVisible = true;
            isGlobalErrorAnimated = true;
        } else if (!tosCheckboxChecked) {
            errorMessage = globalErrorMessages.tosCheckbox;
            isGlobalErrorVisible = true;
            isGlobalErrorAnimated = true;
        } else if (!reCAPTCHAVerified) {
            errorMessage = globalErrorMessages.captcha;
            isGlobalErrorVisible = true;
            isGlobalErrorAnimated = true;
        }

        this.setState({
            isGlobalErrorVisible: isGlobalErrorVisible,
            isGlobalErrorAnimated: isGlobalErrorAnimated,
            globalErrorText: errorMessage,
        });

        this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
    }

    render() {
        const {
            currentRegistrationStep,
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalErrorText,
        } = this.state;

        return pug `
div
    ${isGlobalErrorVisible &&
        pug`
            GlobalErrorNotification(
                notificationText=${globalErrorText}
                isVisible=${isGlobalErrorAnimated}
                handleErrorNotificationClose=${this.handleErrorNotificationClose}
            )
        `
    }
    ul.nav_registr
        li.item_nav(
            onClick=${(e) => this.handleNavClick(e, 0)}
            class=${classNames({'is-active' : (currentRegistrationStep === 0)}, {'is-passed' : (currentRegistrationStep === 1 || currentRegistrationStep == 2)})}
        )
            .icon_nav
        li.item_nav(
            onClick=${(e) => this.handleNavClick(e, 1)}
            class=${classNames({'is-active' : (currentRegistrationStep === 1)}, {'is-passed' : (currentRegistrationStep == 2)})}
        )
            .icon_nav
        li.item_nav(
            onClick=${(e) => this.handleNavClick(e, 2)}
            class=${classNames({'is-active' : (currentRegistrationStep == 2)})}
        )
            .icon_nav
    .wrapper_form-parts
        FormikRegistration_1(
            currentStep=${currentRegistrationStep}
            saveFormValues=${this.handleSubmit}
        )
        FormikRegistration_2(
            currentStep=${currentRegistrationStep}
            saveFormValues=${this.handleSubmit}
        )
        FormikRegistration_3(
            currentStep=${currentRegistrationStep}
            saveFormValues=${this.handleSubmit}
            recaptchaSiteKey=${this.props.recaptchaSiteKey}
            handleErrorOpen=${this.handleErrorOpen}
        )
`
    }
};

export default withRouter(FormRegistration);
