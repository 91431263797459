import React from 'react';



export default function Error() {

    return pug `
    div Error Page
                `
}
