import React from 'react';



export default function FormInput(props) {
    const {
        item,
        name,
        value,
        valid,
        inputClass,
        errorDescription,
        handleBlur,
        handleChange,
        readonly
    } = props;

    return pug `
.group_field(class= ${item.className} class= ${inputClass})
    .icon_field-error
    ${props.children ? props.children : pug`
        input.field_input(
            class=${item.inputClassName}
            autoComplete="off"
            type=${item.type}
            name=${name}
            value=${value}
            onChange=${handleChange}
            onBlur=${handleBlur}
            readOnly=${readonly}
        )`
    }
    .block_field-error-description
        span.text_field-error-description ${errorDescription || 'Please, check the input. There’s an error.'}
    label.label_input ${item.label}
                `
}
