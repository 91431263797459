import axios from 'axios';
import React from 'react';
import classNames from 'classnames';

import {
    injectStripe,
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement
} from 'react-stripe-elements';

import {
    fieldsErrorMessages,
    globalErrorMessages,
    globalNotificationMessages,
} from '../notifications.js';

import {
    valRequired,
    valMaxLength,
    valLetters
} from '../utils.js';

import {
    billing
} from '../authorization.js';
import endpoints from '../endpoints.js';

import FormInput from './../common/FormElems.js';

const formData = {
    name: {
        className: "input_card-name",
        type: "text",
        label: "Cardholder name",
    },
    number: {
        className: "input_card-number",
        type: "text",
        label: "Card number",
    },
    expiry: {
        className: "input_card-date",
        type: "text",
        label: "Expirity date",
    },
    cvc: {
        className: "input_card-cvv",
        type: "text",
        label: "CVV",
    }
};

const stripeStyles = {
    base: {
        color: '#000000',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        lineHeight: '1.23',
    },
    invalid: {
        color: '#000000',
    }
};



class FormCardData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                'name': fieldsErrorMessages.require,
                'cardNumber': fieldsErrorMessages.require,
                'cardExpiry': fieldsErrorMessages.require,
                'cardCvc': fieldsErrorMessages.require,
            },
            values: {
                name: ''
            },
            touched: {
                name: false
            },
            focused: {
                name: false,
                cardNumber: false,
                cardExpiry: false,
                cardCvc: false,
            },
            checkboxChecked: false,
            isSubmitting: false,
            //isGlobalErrorVisible: false,
            //globalErrorText: '',
        }

        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleCommonFieldChange = this.handleCommonFieldChange.bind(this);
        this.handleSecureFieldChange = this.handleSecureFieldChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleCheckbox() {
        this.setState(prevState => {
            return {
                checkboxChecked: !prevState.checkboxChecked,
            }
        })
    }

    handleBlur(props) {
        const touched = Object.assign({}, this.state.touched);

        if (props.elementType) {
            touched[props.elementType] = true;
        } else {
            // props == event
            touched[props.target.name] = true;
        }

        this.setState({
            focused: {},
            touched: touched,
        })
    }

    handleCommonFieldChange(event) {
        const {
            name,
            value
        } = event.target;

        const errors = Object.assign({}, this.state.errors);
        const values = Object.assign({}, this.state.values);

        values[name] = value;

        if (name == 'name') {
            valRequired(values[name]) ? (
                valLetters(values[name]) ? (
                    valMaxLength(values[name], 200) ?
                    (delete errors[name]) :
                    (errors[name] = fieldsErrorMessages.maxLengthCard)
                ) : (errors[name] = fieldsErrorMessages.letters)
            ) : (errors[name] = fieldsErrorMessages.require);
        }

        this.setState({
            values: values,
            errors: errors,
        })
    }

    handleSecureFieldChange(props) {
        const errors = Object.assign({}, this.state.errors);

        if (props.error) {
            errors[props.elementType] = props.error.message
        } else if (props.empty || !props.complete) {
            errors[props.elementType] = fieldsErrorMessages.require
        } else {
            delete errors[props.elementType]
        }

        this.setState({
            errors: errors,
        })
    }

    handleFocus(props) {
        this.setState({
            focused: {
                [props.elementType]: true
            }
        })
    }

    handleSubmit(event) {

        // We don't want to let default form submission happen here, which would refresh the page.
        event.preventDefault();

        const {
            values
        } = this.state;

        if (Object.keys(this.state.errors).length == 0) {
            /* submit form */

            this.setState({
                isSubmitting: true
            });

            // Within the context of `Elements`, this call to createToken knows which Element to
            // tokenize, since there's only one in this group.
            this.props.stripe.createToken({
                    name: values['name'],
                }).then(result => {
                    const {
                        token,
                        error
                    } = result;

                    if (token !== null) {
                        const values = {
                            'token': token.id,
                            'email': this.props.me.email,
                            'full_name': this.state.values['name'],
                            'is_default': this.state.checkboxChecked,
                        };
                        return billing.post(endpoints.stripeCards, values);
                    }
                })
                .then(response => {
                    const card = response.data;
                    if (card.is_default === true) {
                        this.props.clearCurrentPrimaryCard();
                    }
                    this.props.addCard(card);
                    this.props.showGlobalNotification(globalNotificationMessages.successfulAddCard);
                })
                .catch(error => {
                    this.setState({
                        isSubmitting: false
                    });

                    this.props.handleErrorsWhileSendingData(error, null, () => {
                        (error.response.status == 409) && this.props.showGlobalError(globalErrorMessages.cardExist);
                    });
                });

        } else {
            const touched = Object.assign({}, this.state.touched);
            Object.keys(this.state.errors).forEach(key => {
                touched[key] = true;
            });

            this.setState({
                touched: touched
            });

            this.setState({
                isSubmitting: false
            });
        }
    }

    render() {
        const {
            values,
            touched,
            errors,
            focused,
            checkboxChecked,
            isSubmitting,
        } = this.state;

        return pug `
form.form_card-modal(onSubmit=${this.handleSubmit})
    FormInput(
        item=${{
            label:'Cardholder name',
            type: 'text',
        }}
        name=${'name'}
        value=${values['name']}
        inputClass=${touched['name'] && errors['name'] && 'is-invalid'}
        errorDescription=${touched['name'] && errors['name']}
        handleChange=${this.handleCommonFieldChange}
        handleBlur=${this.handleBlur}
    )
    FormInput(
        item=${{label:'Card number'}}
        inputClass=${touched['cardNumber'] && errors['cardNumber'] && classNames({
            'is-focused': focused['cardNumber'],
            'is-invalid': true
        })}
        errorDescription=${touched['cardNumber'] && errors['cardNumber']}
    )
        CardNumberElement(
            placeholder=""
            classes=${{base: 'field_input field_secured'}}
            style=${stripeStyles}
            onChange=${this.handleSecureFieldChange}
            onBlur=${this.handleBlur}
            onFocus=${this.handleFocus}
        )
    FormInput(
        item=${{
            label:'Expirity date',
            className: 'input_card-date',
        }}
        inputClass=${touched['cardExpiry'] && errors['cardExpiry'] && classNames({
            'is-focused': focused['cardExpiry'],
            'is-invalid': true
        })}
        errorDescription=${touched['cardExpiry'] && errors['cardExpiry']}
    )
        CardExpiryElement(
            placeholder=""
            classes=${{base: 'field_input field_secured'}}
            style=${stripeStyles}
            onChange=${this.handleSecureFieldChange}
            onBlur=${this.handleBlur}
            onFocus=${this.handleFocus}
        )
    FormInput(
        item=${{
            label:'CVV',
            className: 'input_card-cvv'
        }}
        inputClass=${touched['cardCvc'] && errors['cardCvc'] && classNames({
            'is-focused': focused['cardCvc'],
            'is-invalid': true
        })}
        errorDescription=${touched['cardCvc'] && errors['cardCvc']}
    )
        CardCVCElement(
            placeholder=""
            classes=${{base: 'field_input field_secured'}}
            style=${stripeStyles}
            onChange=${this.handleSecureFieldChange}
            onBlur=${this.handleBlur}
            onFocus=${this.handleFocus}
        )
    .wrapper_form-bottom
        ${this.props.atLeastOneCardExists && pug`
            .wrapper_checkbox
                label
                    input(type="checkbox" onChange=${this.toggleCheckbox} checked=${checkboxChecked})
                    span.elem_checkbox
                    .title_checkbox Select as Primary payment method
        `}
        button.bttn_save(
            type="submit"
            disabled=${isSubmitting}
            class=${isSubmitting && 'is-disabled'}
        ) Save
        `
    }
};

export default injectStripe(FormCardData);
