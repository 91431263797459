import React from 'react';


import classNames from 'classnames';

export default class SinglePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.printElem = this.printElem.bind(this);
        this.openPdf = this.openPdf.bind(this);
    }

    printElem() {}

    openPdf() {
        window.open(this.props.paymentData.pdfHref)
    }

    render() {

        const {
            date,
            card,
            status,
            amount
        } = this.props.paymentData;

        return pug `
                .row_content.row_content-hovering
                    .inner_wrapper_row
                        .cell_date-payment-history
                            span.text_date-payment-history ${date}
                        .cell_card-payment-history
                            span.text_card-payment-history ${card}
                        .cell_status-payment-history(class=${classNames({
                            'is-paid': (status == 'Paid'),
                            'is-pending': (status == 'Pending'),
                            'is-failed': (status == 'Failed')
                        })})
                            span.text_status-payment-history ${status}
                        .cell_amount-payment-history
                            span.text_amount-payment-history ${amount}
                        .cell_print-payment-history
                            span.bttn_print-payment-history(onClick=${this.printElem})
                        .cell_download-payment-history
                            span(onClick=${this.openPdf}).bttn_download-payment-history
                    `
    }
}
//a(href=${this.props.paymentData.operationHref})
