import React from 'react';

import {
    Route,
    NavLink,
    Switch,
    Link
} from 'react-router-dom';

import FormikLogin from './FormikLogin';
import FormRegistration from './FormRegistration';

import {
    Footer
} from './Footer';

import routes from '../routes.js';

/*Change it*/
const recaptchaSiteKey = '6LctSkQUAAAAADmaIifMbVpY-QcJFJTuABTzWHKh';



export const PageEntrance = () => {

    return pug `
.wrapper_page.wrapper_page_with_footer
    header.header_main
        a(href="/").img_logo
            img(src="/images/Ambisafe_logo_dark.svg" alt="Ambisafe logo")
        h1.title_block Hello, log in/sign up to start working
    .wrapper_forms-login
        .nav_tabs
            NavLink(to=${routes.login} exact activeClassName="is-active")
                .nav_tab-trig(

                ) Log in
            NavLink(to=${routes.registration} activeClassName="is-active")
                .nav_tab-trig(
                ) Sign up
        .tabs_inner_wrapper
            Switch
                Route(
                    exact path=${routes.login})
                    .tab_login
                        FormikLogin(recaptchaSiteKey=${recaptchaSiteKey})
                        .wrapper_text_link-entrance
                            Link(to=${routes.restorePassEmail})
                                p.text_link-entrance I forgot my password
                Route(path=${routes.registration})
                    .tab_register
                        FormRegistration(recaptchaSiteKey=${recaptchaSiteKey})
    Footer
        `
}
