import React from 'react';

const CardPopup = props => {
    const { children, ...rest } = props;

    return pug`
        .wrapper_manage-single-card
            .bttn_manage-single-card(onClick=${props.tooglePopup})
            ${props.popupActive && pug`
                CardPopupController(
                    ...rest
                )
                    ${children}
            `}
    `
}

class CardPopupController extends React.Component {
    constructor(props) {
        super(props);

        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);        
    }

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp, false);
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    handleKeyUp(e) {
        const keys = {
            // 27 is default code for ESC key
            27: () => {
                e.preventDefault();
                window.removeEventListener('keyup', this.handleKeyUp, false);
                this.props.tooglePopup();
            },
        };

        if (keys[e.keyCode]) { keys[e.keyCode](); }
    }

    handleOutsideClick(e) {
        if (!(this.popup === undefined)) {
            if (!this.popup.contains(e.target)) {
                e.preventDefault();
                this.props.tooglePopup();
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
        }
    }

    render() {
        return pug`
            .popup_manage-single-card.is-active(ref=${ popup => this.popup = popup })
                .bttn_primary-single-card(onClick=${this.props.setPrimary}) Set primary
                .bttn_delete-single-card(onClick=${this.props.delete}) Delete
        `
    }
}

export default CardPopup;
