import React from 'react';

import axios from 'axios';

import {
    Formik
} from 'formik';

import endpoints from '../endpoints.js';

import {
    valRequired,
    valPhone,
    valPhoneCountryCode,
    valURL,
    valEmail,
    valMinLength,
    valEqual,
    valLetters
} from '../utils.js';

import {
    fieldsErrorMessages,
    globalErrorMessages,
    globalNotificationMessages,
    autoHideNotifications,
    autoCloseNotificationDuration,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData,
} from '../notifications.js';

import {
    registrationData_1,
    registrationData_2,
    registrationData_3,
    companySizeOptions
} from '../registrationData.js';

import FormProfile from './FormProfile';
import GlobalErrorNotification from '../common/GlobalErrorNotification.js';

import GlobalSuccessNotification from '../common/GlobalSuccessNotification.js';

import zxcvbn from 'zxcvbn';



export default class FormikProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCompanySize: companySizeOptions[0],
            selectedCountry: {},
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            isGlobalNotificationVisible: false,
            isGlobalNotificationAnimated: false,
            zxcvbnScore: 0,
            globalErrorText: '',
            globalNotificationText: '',
            values: setInitialValues(),
            showGlobalCountryError: false,
            isButtonVisible: false,
            countries: [],
        }

        function setInitialValues() {
            const values = {};

            Object.keys(registrationData_1).map((key, index) => {
                if (index === 0 || index === 1) {
                    values[key] = '';
                }
            });

            values.company = '';
            values.company_size = companySizeOptions[0];

            Object.keys(registrationData_2).map((key, index) => {
                if (index === 1 || index === 2) {
                    values[key] = '';
                }
            });

            values.country = '';

            Object.keys(registrationData_3).map((key, index) => {
                values[key] = '';
            });

            values.password = '';
            values.confirm_password = '';
            values.email = '';
            values.billing_email = '';

            return values
        }

        this.validate = this.validate.bind(this);
        this.handleCompanySizeChange = this.handleCompanySizeChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.handleSubmitButtonClick = this.handleSubmitButtonClick.bind(this);
        this.handleButtonVisibility = this.handleButtonVisibility.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        /*Set data into fields*/

        const valuesToSet = Object.assign({}, this.state.values);

        axios.get(endpoints.profileGet)
            .then(response => {
                Object.keys(response.data).forEach(key => {
                    if (key != 'userprofile') {
                        valuesToSet[key] = response.data[key];
                    } else {
                        Object.keys(response.data.userprofile).forEach(key => {
                            valuesToSet[key] = response.data.userprofile[key];
                        })
                    }
                });

                // Should be rewritten
                axios.get(window.location.origin + '/json/countries.json')
                    .then(response => {
                        const countries = [];

                        response.data.forEach(item => {
                            const newItem = {};
                            newItem.value = item.code;
                            newItem.label = item.name;
                            countries.push(newItem);
                        });

                        const selectedCountry = countries.find(country => country.value == valuesToSet.country);

                        this.setState({
                            countries: countries,
                            selectedCountry: selectedCountry,
                        });
                    })
                    .catch(err => {
                        console.log('Failed to load countries list')
                    });

                this.setState({
                    selectedCompanySize: companySizeOptions[valuesToSet.company_size],
                    values: valuesToSet,
                });

                this.props.hidePreloader('.preloader_profile');
            })
            .catch(error => {
                this.setState({
                    isGlobalErrorVisible: true,
                    isGlobalErrorAnimated: true,
                    globalErrorText: globalErrorMessages.serverError,
                });

                this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
            });
    }

    validate(values) {
        const {
            selectedCountry,
            showGlobalCountryError,
        } = this.state;

        let {
            zxcvbnScore
        } = this.state;

        const errors = {};

        if (showGlobalCountryError) {
            if (selectedCountry && Object.keys(selectedCountry).length) {
                delete errors['country'];
            } else {
                errors['country'] = fieldsErrorMessages.country;

                this.setState({
                    isGlobalErrorVisible: true,
                    isGlobalErrorAnimated: true,
                    globalErrorText: fieldsErrorMessages.country,
                    showGlobalCountryError: false,
                });

                this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
            }
        }

        Object.keys(values).forEach(key => {
            if (key == 'email' /* || key == 'billing_email'*/ ) {

                valRequired(values[key]) ? (
                    valEmail(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.email)
                ) : (errors[key] = fieldsErrorMessages.require);

            } else if (key == 'password') {

                zxcvbnScore = zxcvbn(values[key]).score;

                if (valRequired(values[key])) {
                    zxcvbnScore > 1 ?
                        (delete errors[key]) :
                        (errors[key] = fieldsErrorMessages.password);
                }

                valEqual(values['confirm_password'], values[key]) ?
                    (delete errors['confirm_password']) :
                    (errors['confirm_password'] = fieldsErrorMessages.confirmPassword);

            } else if (key == 'confirm_password') {

                if (valRequired(values['password'])) {
                    valRequired(values[key]) ? (
                        valEqual(values[key], values['password']) ?
                        (delete errors[key]) :
                        (errors[key] = fieldsErrorMessages.confirmPassword)
                    ) : (errors[key] = fieldsErrorMessages.confirmPassword);
                }

            } else if (key == 'phone') {

                valRequired(values[key]) ? (
                    valPhone(values[key]) ? (
                        valPhoneCountryCode(values[key]) ?
                        (delete errors[key]) :
                        (errors[key] = fieldsErrorMessages.phoneCountryCode)
                    ) : (errors[key] = fieldsErrorMessages.phone)
                ) : (errors[key] = fieldsErrorMessages.require);

            } else if (key == 'website') {

                valURL(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.website);

            } else if (key == 'state') {

                valLetters(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.letters)
            } else if (key == 'first_name' || key == 'last_name' || key == 'company' || key == 'title' || key == 'city') {

                valRequired(values[key]) ? (
                    valLetters(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.letters)
                ) : (errors[key] = fieldsErrorMessages.require);
            }
        });

        this.setState({
            zxcvbnScore: zxcvbnScore,
        });

        return errors
    }

    handleCompanySizeChange(selectedCompanySize) {
        this.setState({
            selectedCompanySize: selectedCompanySize,
        });
    }
    handleCountryChange(selectedCountry) {
        this.setState({
            selectedCountry: selectedCountry,
        });
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    handleSubmitButtonClick() {
        this.setState({
            showGlobalCountryError: true,
        });
    }

    handleButtonVisibility(isButtonVisible) {
        this.setState({
            isButtonVisible: isButtonVisible,
        });
    }

    handleSubmit(values, formikBag) {
        const valuesToSend = {};
        const passwordToSend = {};

        let errorMessage;

        ['first_name', 'last_name', 'company', 'company_size', 'title', 'website', 'country', 'state', 'city', 'phone']
        .forEach(key => {
            valuesToSend[key] = values[key];
        });

        if (values['password']) {
            ['password', 'confirm_password']
            .forEach(key => {
                if (values[key]) {
                    passwordToSend[key] = values[key];
                }
            });
        };

        axios.all([
                axios.put(endpoints.profileChange, valuesToSend),
                (values['password'] && axios.put(endpoints.profilePasswordChange, passwordToSend)),
            ])
            .then(axios.spread((firstResponse, secondResponse) => {
                const valuesToSet = Object.assign({}, valuesToSend);

                valuesToSet['password'] = '';
                valuesToSet['confirm_password'] = '';

                this.setState({
                    values: valuesToSet,
                    isButtonVisible: false,
                    isGlobalNotificationVisible: true,
                    isGlobalNotificationAnimated: true,
                    globalNotificationText: globalNotificationMessages.successfulProfileUpdate,
                });

                this.autoHideNotifications('isGlobalNotificationVisible', autoCloseNotificationDuration, 'isGlobalNotificationAnimated');

                formikBag.resetForm();
                formikBag.setSubmitting(false);
            }))
            .catch(error => {
                formikBag.setSubmitting(false);
                this.handleErrorsWhileSendingData(error, formikBag)
            });
    }

    render() {
        const {
            globalErrorText,
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalNotificationText,
            isGlobalNotificationVisible,
            isGlobalNotificationAnimated,
            selectedCompanySize,
            selectedCountry,
            values,
            countries,
            isButtonVisible,
        } = this.state;

        return pug `
div
    ${isGlobalErrorVisible &&
        pug`
            GlobalErrorNotification(
                notificationText=${globalErrorText}
                isVisible=${isGlobalErrorAnimated}
                handleErrorNotificationClose=${this.handleErrorNotificationClose}
            )
        `
    }
    ${isGlobalNotificationVisible &&
        pug`
            GlobalSuccessNotification(
                notificationText=${globalNotificationText}
                isVisible=${isGlobalNotificationAnimated}
            )
        `
    }
    Formik(render=${formikProps =>
            pug`
                FormProfile(
                    ...formikProps
                    ...this.props
                    selectedCompanySize=${selectedCompanySize}
                    handleCompanySizeChange=${this.handleCompanySizeChange}
                    selectedCountry=${selectedCountry}
                    handleCountryChange=${this.handleCountryChange}
                    prevValues=${values}
                    registrationData_1=${registrationData_1}
                    registrationData_2=${registrationData_2}
                    registrationData_3=${registrationData_3}
                    companySizeOptions=${companySizeOptions}
                    countries=${countries}
                    handleSubmitButtonClick=${this.handleSubmitButtonClick}
                    handleButtonVisibility=${this.handleButtonVisibility}
                    isButtonVisible=${isButtonVisible}
                )`
            }
            enableReinitialize=${true}
            validate=${this.validate}
            onSubmit=${this.handleSubmit}
            validateOnBlur=${false}
            initialValues=${values}

        )
`
    }
};
