import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';

import '../../fonts/fonts.scss';
import '../../stylesheets/scss/style.scss';

//import '../../stylesheets/scss/external/text-security/dist/text-security-disc.scss';

import {
    StripeProvider
} from 'react-stripe-elements';

import {
  render
} from 'react-dom';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import Raven from 'raven-js';

import {
  getElem,
  hidePreloader
} from './utils.js';

import MainMenu from './main_menu/MainMenu';

import {
    PageEntrance
} from './p_entrance/PageEntrance';

import RestorePass1 from './p_entrance/RestorePass1';
import RestorePass2 from './p_entrance/RestorePass2';
import Error from './p_error/Error';

import EmailConfirmation from './common/EmailConfirmation';

import routes from './routes.js';

import {
    userAuth,
    refreshAccessTokenLoop,
} from './authorization.js';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.API_BASE_URL;

if (process.env.USE_SENTRY === true){
  Raven.config("https://571fa95c2e4c4b76a22af90c748d917d@sentry.ambisafe.io/18").install();
}

refreshAccessTokenLoop();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      window.localStorage.getItem('authorizationToken') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);



render(
    // <StripeProvider apiKey="pk_test_1">
    <StripeProvider apiKey="pk_live_JDWvBdKC7SmeQdASxCQR6LNn">
        <Router>
            <Switch>
                    <Route
                        path = {routes.login}
                        component = {PageEntrance}
                    />
                    <Route
                        path = {routes.registration}
                        component = {PageEntrance}
                    />
                    <PrivateRoute
                        path = {routes.dashboard}
                        component = {MainMenu}
                    />
                    <PrivateRoute
                        path = {routes.paymentHistory}
                        component = {MainMenu}
                    />
                    <PrivateRoute
                        path = {routes.paymentMethods}
                        component = {MainMenu}
                    />
                    <PrivateRoute
                        path = {routes.profile}
                        component = {MainMenu}
                    />
                    <Route
                        path = {routes.restorePassEmail}
                        component = {RestorePass1}
                    />
                    <Route
                        path = {routes.restorePassPassword}
                        component = {RestorePass2}
                    />
                    <Route
                        path = {routes.emailConfirmation}
                        component = {EmailConfirmation}
                    />
                    <Redirect
                      from = "/"
                      to = {routes.login}
                    />
              </Switch>
          </Router>
    </StripeProvider>
, getElem('#root')[0]);

/* Disable preloader */

hidePreloader('.preloader_global');

window.Intercom('boot', {app_id: 'qj9ssyd9'});
