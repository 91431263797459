import React from 'react';

export const globalErrorMessages = {
    'ppCheckbox': 'We cannot supply services to you if you don’t agree with our Privacy policy.',
    'tosCheckbox': 'We cannot supply services to you if you don’t agree with our Terms of Service.',
    'captcha': 'Please pass the reCAPTCHA (we are not afraid of robots, but anyway).',
    'serverError': pug `.text_notification Application mistakes sometimes happen, sorry. Please try again or contact support: #[a(href="mailto:support@ambisafe.com" target="") support@ambisafe.com]`,
    'invalidValues': 'Please make sure you provided the correct data.',
    'requiredFields': 'Please complete the mandatory fields.',
    'cardExist': 'This card is already added.',
    'emailConfirmationTokenIsWrong': 'An error occurred while validating your confirmation token.',
};

export const fieldsErrorMessages = {
    'password': 'Please choose a stronger password.',
    'email': 'Please enter a valid email address.',
    'require': 'Please complete this mandatory field.',
    'phone': 'Please enter a valid phone number with a country code.',
    'phoneCountryCode': 'This phone number belongs to one of the OFAC imposed restricted countries.',
    'confirmPassword': 'Please make sure your password and confirm password values match.',
    'website': 'Please make sure the website address is correct.',
    'letters': 'Please, provide all the information in English.',
    'country': 'Please add your country.',
    'invalidValues': pug `span You might have filled in this field incorrectly. #[br] Please try again.`,
    'maxLengthCard': 'Ensure this field has no more than 200 characters.'
};

const notificationAnimationDuration = 500;
export const autoCloseErrorDuration = 5000;
export const autoCloseNotificationDuration = 3000;

export function autoHideNotifications(stateToChange, durationToClose, asyncStateToChange) {
    const $this = this;

    setTimeout(() => {
        asyncStateToChange && $this.setState({
            [asyncStateToChange]: false,
        });
    }, durationToClose);

    setTimeout(() => {
        $this.setState({
            [stateToChange]: false,
        });
    }, durationToClose + notificationAnimationDuration);
};


export const globalNotificationMessages = {
    successfulRegistration: 'Your data is saved. To complete the registration, please follow the verification link. We sent it by email.',
    successfulPasswordChange: 'You successfully changed your password.',
    successfulProfileUpdate: 'You successfully updated your profile.',
    successfulAddCard: 'You added a new payment method successfully. You can change it on the ‘Payment method’ page any time.',
    successfulRemoveCard: 'You removed the payment method successfully. You can add a new one on the ‘Payment method’ page any time.',
    successfulPrimaryCardChange: 'You have changed you primary payment method successfully. You can add a new one on the ‘Payment method’ page any time.',
    successfulConfirmEmail: 'Your email address has been successful confirmed',
};

export function handleErrorsWhileSendingData(error, formikBag, customFunction) {
    let errorMessage;

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (typeof error.response.data != 'object') {
            errorMessage = globalErrorMessages.serverError;
        } else if (customFunction) {
            try {
                errorMessage = customFunction();
            } catch (err) {
                errorMessage = globalErrorMessages.serverError;
            }
        } else if (typeof error.response.data == 'object') {
            try {
                Object.keys(error.response.data).forEach(key => {

                    if (formikBag) {
                        if (key == 'userprofile') {
                            Object.keys(error.response.data.userprofile).forEach(userProfileKey => {
                                formikBag.setFieldError(key, error.response.data.userprofile[userProfileKey][0]);
                            });
                        } else {
                            formikBag.setFieldError(key, error.response.data[key][0]);
                        }
                    }

                    if (key == 'non_field_errors') {
                        errorMessage = error.response.data.non_field_errors;
                    } else if (key == 'token') {
                        errorMessage = globalErrorMessages.invalidValues;
                    } else if (!formikBag) {
                        errorMessage = globalErrorMessages.serverError;
                    }
                });
            } catch (err) {
                errorMessage = globalErrorMessages.serverError;
            }
        }

    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorMessage = globalErrorMessages.serverError;
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = globalErrorMessages.serverError;
    }
    if (errorMessage) {
        this.setState({
            isGlobalErrorVisible: true,
            isGlobalErrorAnimated: true,
            globalErrorText: errorMessage,
        });

        this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
    }
}
