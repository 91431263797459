const consoleAccounts = {
  login: 'v1/accounts/login/',
  signUp: 'v1/accounts/signup/',
  passwordReset: 'v1/accounts/password/reset/',
  passwordResetConfirm: 'v1/accounts/password/reset/confirmation/',
  profileGet: 'v1/accounts/me/',
  profileChange: 'v1/accounts/profile/',
  profilePasswordChange: 'v1/accounts/password/change/',
  confirmEmail: 'v1/accounts/email/confirmation/',
  refreshAccessToken: 'v1/accounts/refresh-jwt-token/'
}


const billingServices = {
  dashboardWallet: 'v1/services/', // ???
  dashboardAmbiVault: 'v1/usage/ambivault/organizations/',
  menuAmbiVault: 'v1/services/ambivault/signup/url/',
  stripeCards: '/v1/stripe/cards/',
  stripeCard: pk => `/v1/stripe/cards/${pk}/`,
  invoices: 'v1/invoices/',
};

const endpoints = Object.assign({}, consoleAccounts, billingServices);

export default endpoints;
