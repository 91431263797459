import React from 'react';

import {
    Redirect,
    withRouter
} from 'react-router';

import axios from "axios";

import endpoints from '../endpoints.js';

import routes from '../routes.js';



class EmailConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
        }
    }

    componentDidMount() {

        let token = this.props.location.pathname.split('/');

        (token.length > 2 && token[2].length) ? (token = token[2]) : (token = false)

        axios.post(endpoints.confirmEmail, {
            token: token
        }).then((response) => {
            localStorage.setItem('successfulEmailConfirmation', true);

            this.setState({
                redirectToReferrer: true,
            });
        }).catch((error) => {
            localStorage.setItem('errorEmailConfirmation', true);

            this.setState({
                redirectToReferrer: true,
            });
        });
    }

    render() {
        if (this.state.redirectToReferrer) {
            return pug `Redirect(push to=${routes.login})`;
        } else {
            return null
        }
    }
};



export default withRouter(EmailConfirmation);
