import React from 'react';

import {
    formatAmount,
    compareYears
} from '../utils.js';

import Moment from 'react-moment';



export function RowTableCryptoWallet({
    item
}) {

    const status = {
        class: item.is_paid ? 'is-paid' : 'is-pending',
        text: item.is_paid ? 'Paid' : 'Pending payment'
    };


    return pug `
    .row_content
        .inner_wrapper_row
            .cell_period-CW
                .text_period-CW
                    Moment(format=${compareYears(item.period_start, item.period_end) ? 'MMM DD' : 'MMM DD, YYYY'})
                        ${item.period_start}
                    span &#160;-&#160;
                    Moment(format="MMM DD, YYYY")
                        ${item.period_end}
            .cell_users-CW
                .text_users-CW ${ formatAmount(+item.operations_count, 0, 0) }
            .cell_price-CW
                .text_price-CW ${ '$' + formatAmount(+item.operations_price, 2, 2) }
            .cell_total-CW
                .text_total-CW ${ '$' + formatAmount(+item.total_sum, 0, 2) }
            .cell_status-CW
                .text_status-CW(class=${ status.class }) ${ status.text }
            `
}

export function RowTableAmbisafeVault({
    item
}) {

    const status = {
        class: item.is_paid ? 'is-paid' : 'is-pending',
        text: item.is_paid ? 'Paid' : 'Pending payment'
    };

    return pug `
    .row_content
        .inner_wrapper_row
            .cell_period-AV
                .text_period-AV
                    Moment(format=${compareYears(item.period_start, item.period_end) ? 'MMM DD' : 'MMM DD, YYYY'})
                        ${item.period_start}
                    span &#160;-&#160;
                    Moment(format="MMM DD, YYYY")
                        ${item.period_end}
            .cell_operations-AV
                .text_operations-AV ${ formatAmount(+item.operations_count, 0, 0) }
            .cell_price-AV
                .text_price-AV ${ '$' + formatAmount(+item.operations_price, 2, 2) }
            .cell_subscription-AV
                .text_subscription-AV ${ '$' + formatAmount(+item.subscription, 2, 2) }
            .cell_total-AV
                .text_total-AV ${ '$' + formatAmount(+item.total_sum, 0, 2) }
            .cell_status-AV
                .text_status-AV(class=${ status.class }) ${ status.text }
            `
}
