import React from 'react';

import CardPopup from './CardPopup.js';

import endpoints from '../endpoints.js';
import {
    billing
} from '../authorization.js';

import {
    globalNotificationMessages,
} from '../notifications.js';

import {
    camelize
} from '../utils.js';


export default class Card extends React.Component {
    constructor(props) {
        super(props);

        const card = this.props.card;

        this.state = {
            popupActive: false,
        }
        this.expYear = String(card.exp_year).slice(-2);
        this.expMonth = card.exp_month < 10 ? '0' + String(card.exp_month) : card.exp_month;

        this.tooglePopup = this.tooglePopup.bind(this);
        this.delete = this.delete.bind(this);
        this.setPrimary = this.setPrimary.bind(this);
    }

    tooglePopup() {
        this.setState(prevState => ({
            popupActive: !prevState.popupActive,
        }));
    }

    delete() {
        const {
            card
        } = this.props;
        this.tooglePopup();
        billing.delete(endpoints.stripeCard(card.id))
            .then(response => {
                this.props.removeCard(card.id);
                this.props.showGlobalNotification(globalNotificationMessages.successfulRemoveCard);
            })
            .catch(error => {
                this.props.handleErrorsWhileSendingData(error);
            })
    }

    setPrimary() {
        const {
            card
        } = this.props;
        this.tooglePopup();
        billing.put(endpoints.stripeCard(card.id), { ...card,
                'is_default': true
            })
            .then(response => {
                this.props.setPrimary(card.id);
                this.props.showGlobalNotification(globalNotificationMessages.successfulPrimaryCardChange);
            })
            .catch(error => {
                this.props.handleErrorsWhileSendingData(error);
            })
    }

    render() {
        const {
            popupActive
        } = this.state;
        const {
            card
        } = this.props;
        
        return pug `
            .wrapper_item-card
                .item_single-card(class=${'item_single-card-' + camelize(card.brand)})
                    .img_bg_single-card
                    .block_content_single-card
                        .block_manage-single-card
                            ${card.is_default ?
                                pug`span.text_status-single-card Primary` :
                                pug`
                                    CardPopup(
                                        card=${card}
                                        popupActive=${popupActive}
                                        tooglePopup=${this.tooglePopup}
                                        delete=${this.delete}
                                        setPrimary=${this.setPrimary}
                                    )
                                `
                            }
                        .icon_single-card-payment-system ${card.brand.toUpperCase()}
                        .number_single-card XXXX XXXX XXXX ${card.last_digits}
                        .wrapper_single-card-data
                            .name_single-card ${card.cardholder_name}
                            .date_single-card ${this.expMonth} / ${this.expYear}

        `
    }
}
