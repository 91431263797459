import React from 'react';
import ReactDOM from 'react-dom';

import {
    val_number
} from '../utils.js';


export default class DateFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startVal: '09/21/2016',
            endVal: '09/21/2017',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange() {}

    handleBlur() {}

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        return pug `
        form.wrapper_filter-payment-history(onSubmit= ${this.handleSubmit})
            span.label_filter-payment-history.label_start-payment-history Start
            input(
                value=${this.state.startVal}
                onChange= ${this.handleChange}
            ).field_filter-payment-history
            span.label_filter-payment-history.label_end-payment-history End
            input(
                value=${this.state.endVal}
                onChange= ${this.handleChange}
            ).field_filter-payment-history
            button.bttn_filter-payment-history Filter
                    `
    }
}
