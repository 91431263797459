import React from 'react';



export default class GlobalSuccessNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.isVisible) {
            return {
                isVisible: false,
            }
        }

        return null
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isVisible: true
            });
        }, 50);
    }


    render() {
        const {
            notificationText
        } = this.props;

        const {
            isVisible
        } = this.state;

        return pug `
.block_notification-success(class=${isVisible ? 'is-visible' : ''})
    .wrapper_text-notification
        .icon_notification
        .text_notification ${notificationText || 'You make something cool'}
        `
    }
}
