import React from 'react';

import {
    Formik
} from 'formik';

import FormInput from './../common/FormElems.js';

import {
    fieldsErrorMessages
} from '../notifications.js';

import {
    setValueToAllKeysInObj,
    valEqual,
    valEmail,
    valRequired,
    valMinLength,
    valLetters
} from '../utils.js';

import {
    registrationData_1
} from '../registrationData.js';

import zxcvbn from 'zxcvbn';

const formData = registrationData_1;



const FormRegistration_1 = props => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        currentStep,
    } = props;

    if (currentStep !== 0) {
        return null;
    }

    return pug `
            form.form_register-personal.tab_form(onSubmit=${handleSubmit} noValidate autoComplete="off")
                ${Object.keys(formData).map((key, index) =>
                    pug`
                        FormInput(
                            key=${index}
                            item=${formData[key]}
                            name=${key}
                            value=${values[key]}
                            inputClass=${touched[key] && errors[key] && 'is-invalid'}
                            errorDescription=${touched[key] && errors[key]}
                            handleChange=${handleChange}
                            handleBlur=${handleBlur}
                        )
                    `
                )}
                button.bttn_submit(type="submit") Continue
        `
};



export default class FormikRegistration_1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            zxcvbnScore: 0,
        }

        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validate(values) {
        let zxcvbnScore = this.state.zxcvbnScore;

        const errors = Object.assign({}, this.state.errors);

        Object.keys(values).forEach(key => {

            if (key == 'first_name' || key == 'last_name') {

                valRequired(values[key]) ? (
                    valLetters(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.letters)
                ) : (errors[key] = fieldsErrorMessages.require);

            } else if (key == 'email') {

                valRequired(values[key]) ? (
                    valEmail(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.email)
                ) : (errors[key] = fieldsErrorMessages.require);

            } else if (key == 'password') {

                zxcvbnScore = zxcvbn(values[key]).score;

                valRequired(values[key]) ? (
                    zxcvbnScore > 1 ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.password)
                ) : (errors[key] = fieldsErrorMessages.require);

                valEqual(values['confirm_password'], values[key]) ?
                    (delete errors['confirm_password']) :
                    (errors['confirm_password'] = fieldsErrorMessages.confirmPassword);

            } else if (key == 'confirm_password') {
                valRequired(values[key]) ? (
                    valEqual(values[key], values['password']) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.confirmPassword)
                ) : (errors[key] = fieldsErrorMessages.require);
            }
        });

        this.setState({
            errors: errors,
            zxcvbnScore: zxcvbnScore,
        });

        return errors
    }

    handleSubmit(values, formikBag) {
        this.props.saveFormValues(1, values, formikBag);
    }

    render() {
        return pug `
Formik(render=${formikProps =>
            pug`FormRegistration_1(
                    ...formikProps
                    ...this.props
                )`
            }
        validate=${this.validate}
        onSubmit=${this.handleSubmit}
        validateOnBlur=${false}
        initialValues=${setValueToAllKeysInObj(formData, '')}
    )
`
    }
};
