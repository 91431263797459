import validator from 'validator';

import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

import {
    fieldsErrorMessages
} from './notifications';


export const compareYears = (periodStart, periodEnd) => {
    try {
        const yearOfStart = periodStart.substring(0, 4);
        const yearOfEnd = periodEnd.substring(0, 4);
        return (yearOfStart == yearOfEnd);
    } catch (err) {}
}

export const formatAmount = (value, minDigits = 0, maxDigits = 2) => {
    return value.toLocaleString('en-IN', {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
    })
}


export const setValueToAllKeysInObj = (obj, value) => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key] = value;
    });
    return newObj
}

export const getElem = (selector) => {
    return document.querySelectorAll(selector)
}

export const clipObject = (startIndex, endIndex, originalObject) => {
    const clippedObject = {},
        keys = Object.keys(originalObject);

    for (let i = startIndex; i < endIndex; i++) {
        clippedObject[keys[i]] = originalObject[keys[i]]
    }
    return clippedObject
}

export const valURL = value => {
    if (!validator.isURL(value) && value.toString().trim().length) {
        return false
    }
    return true
}

export const valPhone = value => {
    const phoneInCorrectFormat = '+' + validator.whitelist(value, '0-9');

    try {
        const number = phoneUtil.parse(phoneInCorrectFormat);
        if (!phoneUtil.isValidNumber(number)) {
            return false
        }
    } catch (err) {
        return false
    }

    return true
}

export const valPhoneCountryCode = value => {
    const phoneInCorrectFormat = '+' + validator.whitelist(value, '0-9');

    try {
        const number = phoneUtil.parse(phoneInCorrectFormat);

        const restrictedCountryCodes = [95, 225, 53, 98, 850, 971, 963, 355, 383, 242, 964, 231, 357, 218, 93, 252, 249, 263, 291, 243, 961, 236];

        //const restrictedCountries = ['HR', 'XK', 'AL', 'BA', 'BI', 'CU', 'IR', 'KP', 'LB', 'LY', 'MO', 'MK', 'CS', 'SO', 'SD', 'SY', 'AE', 'RS', 'ME'];

        const isCodeRestricted = restrictedCountryCodes.find(countryCode => {
            return countryCode == number.getCountryCode();
        });

        if (isCodeRestricted) {
            return false
        }

    } catch (err) {
        return false
    }

    return true
}

export const valEqual = (value, valueToCompare) => {
    if (!validator.equals(value, valueToCompare) && value.toString().trim().length) {
        return false;
    }
    return true
}

export const valEmail = value => {
    if (!validator.isEmail(value) && value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return false;
    }
    return true
}

export const valRequired = value => {

    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return false;
    }
    return true
};

export const valNumber = value => {};


export const valMaxLength = (value, maxLength) => {
    if (maxLength && value.toString().trim().length > maxLength) {
        return false
    }
    return true
};

export const valMinLength = (value, minLength) => {
    if (minLength && value.toString().trim().length < minLength && value.toString().trim().length) {
        return false
    }
    return true
};


export const valLetters = value => {
    if (value.toString().trim().length > 0 && !validator.isAlpha(validator.blacklist(value, " \'-"))) {
        return false
    }
    return true
};

export const hidePreloader = (uniqueClassName) => {
    getElem('.preloader' + (uniqueClassName || '')).forEach(preloader => {
        preloader.classList.add('is-loaded');
    });

    setTimeout(() => {
        getElem('.preloader' + (uniqueClassName || '')).forEach(preloader => {
            preloader.style.display = 'none';
        });
    }, 500);
};

export const showPreloader = (uniqueClassName) => {
    getElem('.preloader' + (uniqueClassName || '')).forEach(preloader => {
        preloader.style.display = 'block';
    });

    setTimeout(() => {
        getElem('.preloader' + (uniqueClassName || '')).forEach(preloader => {
            preloader.classList.remove('is-loaded');
        });
    }, 50);
};

function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
    return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '');
}
