import React from 'react';

import FormInput from './../common/FormElems.js';

import axios from 'axios';

import {
    Formik
} from 'formik';

import ReCAPTCHA from 'react-google-recaptcha';

import {
    registrationData_3
} from '../registrationData.js';

import {
    valPhone,
    valPhoneCountryCode,
    valRequired,
    valLetters,
    setValueToAllKeysInObj
} from '../utils.js';
import {
    fieldsErrorMessages
} from '../notifications.js';

import Select from 'react-select';

import '../../../stylesheets/scss/external/react-select.scss';

const formData = registrationData_3;



function FormRegistration_3({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
    currentStep,
    recaptchaVerifedCallback,
    recaptchaExpiredCallback,
    recaptchaSiteKey,
    toggleCheckbox,
    ppCheckboxChecked,
    tosCheckboxChecked,
    countries,
    selectedValue,
    handleSelectChange,
    setErrors,
    handleButtonClick,
    isSubmitting,
}) {

    if (currentStep !== 2) {
        return null;
    }

    function handleFieldChange(event) {
        const {
            target
        } = event;

        handleChange(event);

        if (target.name == 'phone') {
            const valuesToSet = Object.assign({}, values);
            valuesToSet.phone = target.value[0] ? (target.value[0].match(/(\d|\+)/) ? target.value[0] : '') : '';
            valuesToSet.phone += target.value.substring(1).replace(/\D/g, '');
            setValues(valuesToSet);
        }
    }

    function handleSelectOptionChange(option) {

        const errorsToSet = Object.assign({}, errors);

        (option && Object.keys(option).length) && (delete errorsToSet['country']);

        setErrors(errorsToSet);

        handleSelectChange(option);
    }


    return pug `
form.form_register-personal.tab_form(onSubmit=${handleSubmit} noValidate autoComplete="off")
    .group_field.group_dropdown(class=${errors['country'] && 'is-invalid'})
        label.label_input Country
        Select(
            name="country"
            value=${selectedValue}
            clearable=${false}
            searchable=${true}
            filterOptions=${true}
            onChange=${handleSelectOptionChange}
            options=${countries}
            multi=${false}
            placeholder=""
            noResultsText=${fieldsErrorMessages.country}
            matchPos="start"
        )
    ${Object.keys(formData).map((key, index) =>
        pug`
            FormInput(
                key=${index}
                item=${formData[key]}
                name=${key}
                value=${values[key]}
                inputClass=${touched[key] && errors[key] && 'is-invalid'}
                errorDescription=${touched[key] && errors[key]}
                handleChange=${handleFieldChange}
                handleBlur=${handleBlur}
            )
        `
    )}
    .wrapper_checkbox
        label
            input(type="checkbox" onChange=${toggleCheckbox('pp')} checked=${ppCheckboxChecked})
            span.elem_checkbox
            .title_checkbox I have read Ambisafe #[a(href=${window.location.origin + '/docs/AmbisafePrivacy_Policy.pdf'} target="_blank" class="link_form") Privacy Policy], understand my rights and agree with how and for what purpose my personal data will be used by Ambisafe.
    .wrapper_checkbox
        label
            input(type="checkbox" onChange=${toggleCheckbox('tos')} checked=${tosCheckboxChecked})
            span.elem_checkbox
            .title_checkbox By signing up I agree to the #[a(href=${window.location.origin + '/docs/AmbisafeTerms_Of_Service.pdf'} target="_blank" class="link_form") Terms of Service].
    .wrapper_captcha
        .inner_wrapper_captcha
            ReCAPTCHA(
                sitekey=${recaptchaSiteKey}
                onChange=${recaptchaVerifedCallback}
                onExpired=${recaptchaExpiredCallback}
            )
    button.bttn_submit(
        type="submit"
        onClick=${handleButtonClick}
        disabled=${isSubmitting}
        class=${isSubmitting && 'is-disabled'}
    ) Sign up
`
}



export default class FormikRegistration_3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ppCheckboxChecked: false,
            tosCheckboxChecked: false,
            reCAPTCHAVerified: false,
            selectedOption: {},
            showGlobalCountryError: false,
        }

        // Should be rewritten
        axios.get(window.location.origin + '/json/countries.json')
            .then(response => {
                const countries = [];

                response.data.forEach(item => {
                    const newItem = {};
                    newItem.value = item.code;
                    newItem.label = item.name;
                    countries.push(newItem);
                });

                this.setState({
                    countries: countries
                });
            })
            .catch(err => {
                console.log('Failed to load countries list')
            });

        this.validate = this.validate.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.recaptchaVerifedCallback = this.recaptchaVerifedCallback.bind(this);
        this.recaptchaExpiredCallback = this.recaptchaExpiredCallback.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    handleSelectChange(selectedOption) {
        this.setState({
            selectedOption: selectedOption,
        });
    }

    validate(values) {
        const {
            selectedOption,
            showGlobalCountryError
        } = this.state;

        const errors = {};

        if (showGlobalCountryError) {
            if (selectedOption && Object.keys(selectedOption).length) {
                delete errors['country'];
            } else {
                errors['country'] = fieldsErrorMessages.country;
                this.props.handleErrorOpen(selectedOption);
            }
            this.setState({
                showGlobalCountryError: false
            });
        }

        Object.keys(values).forEach(key => {
            if (key == 'city') {

                valRequired(values[key]) ? (
                    valLetters(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.letters)
                ) : (errors[key] = fieldsErrorMessages.require);

            } else if (key == 'phone') {

                valRequired(values[key]) ? (
                    valPhone(values[key]) ? (
                        valPhoneCountryCode(values[key]) ?
                        (delete errors[key]) :
                        (errors[key] = fieldsErrorMessages.phoneCountryCode)
                    ) : (errors[key] = fieldsErrorMessages.phone)
                ) : (errors[key] = fieldsErrorMessages.require);
            } else if (key == 'state') {

                valLetters(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.letters)
            }
        });

        return errors
    }

    toggleCheckbox(name) {
        const self = this
        return function() {
            const checkboxChecked = `${name}CheckboxChecked`

            self.setState(prevState => {
                return {
                    [checkboxChecked]: !prevState[checkboxChecked],
                }
            })
        }
    }

    recaptchaVerifedCallback() {
        this.setState({
            reCAPTCHAVerified: true,
        });
    }

    recaptchaExpiredCallback() {
        this.setState({
            reCAPTCHAVerified: false,
        });
    }

    handleButtonClick() {
        this.setState({
            showGlobalCountryError: true,
        });
    }

    handleSubmit(values, formikBag) {
        const {
            ppCheckboxChecked,
            tosCheckboxChecked,
            reCAPTCHAVerified,
            selectedOption,
        } = this.state;

        const {
            handleErrorOpen,
            saveFormValues,
        } = this.props;

        const valuesToSave = Object.assign({}, values, {
            accepted_pp: ppCheckboxChecked,
            accepted_tos: tosCheckboxChecked,
            country: selectedOption.value
        });


        if (ppCheckboxChecked && tosCheckboxChecked && reCAPTCHAVerified && selectedOption) {
            saveFormValues(3, valuesToSave, formikBag);
        } else {
            formikBag.setSubmitting(false);

            handleErrorOpen(selectedOption, ppCheckboxChecked, tosCheckboxChecked, reCAPTCHAVerified);
        }
    }

    render() {
        return pug `
  Formik(render=${formikProps =>
          pug`
              FormRegistration_3(
                  ...formikProps
                  ...this.props
                  ppCheckboxChecked=${this.state.ppCheckboxChecked}
                  tosCheckboxChecked=${this.state.tosCheckboxChecked}
                  toggleCheckbox=${this.toggleCheckbox}
                  recaptchaVerifedCallback=${this.recaptchaVerifedCallback}
                  recaptchaExpiredCallback=${this.recaptchaExpiredCallback}
                  countries=${this.state.countries}
                  selectedValue=${this.state.selectedOption}
                  handleSelectChange=${this.handleSelectChange}
                  handleButtonClick=${this.handleButtonClick}
              )`
          }
          validate=${this.validate}
          onSubmit=${this.handleSubmit}
          validateOnBlur=${false}
          initialValues=${setValueToAllKeysInObj(formData, '')}

      )
  `
    }
};
