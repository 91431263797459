import React from 'react';

import Moment from 'react-moment';

import {
    formatAmount,
    compareYears
} from '../utils.js';



export default function TableDashboard({
    servicesDetailsIsVisible,
    handleClick,
    invoicesSummaryData,
}) {

    return pug `
            .wrapper_enabled-dashboard
                .button_toggle-details(class=${servicesDetailsIsVisible ? 'is-visible' : 'is-hidden'} onClick=${handleClick}) ${servicesDetailsIsVisible ? 'Hide details' : 'Show details'}
                .col_status-payment-dashboard(class=${invoicesSummaryData.allPaid ? 'is-paid' : 'is-pending'})
                    ${invoicesSummaryData.allPaid ? pug`span Paid` : pug `span Pending #[br] payment`}
                .table_dashboard
                    .row_title
                        .inner_wrapper_row
                            .cell_period-dashboard Billing period
                            .cell_total-dashboard ${invoicesSummaryData.allPaid ? 'Total paid' : 'Total to pay'}
                    .row_content
                        .inner_wrapper_row
                            .cell_period-dashboard
                                .text_period-dashboard
                                    Moment(format=${compareYears(invoicesSummaryData.periodStart, invoicesSummaryData.periodEnd) ? 'MMM DD' : 'MMM DD, YYYY'})
                                        ${invoicesSummaryData.periodStart}
                                    span &#160;-&#160;
                                    Moment(format="MMM DD, YYYY")
                                        ${invoicesSummaryData.periodEnd}
                            .cell_total-dashboard
                                span.text_total-dashboard ${'$' + formatAmount(+invoicesSummaryData.totalSum, 0, 2)}
                    `
}
