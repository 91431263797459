import React from 'react';

import Select from 'react-select';

import '../../../stylesheets/scss/external/react-select.scss';

import FormInput from './../common/FormElems.js';

import classNames from 'classnames';

import {
    fieldsErrorMessages,
} from '../notifications.js';


export default class FormProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValuesChanged: false,
            isCountryOptionChanged: false,
            isCompanySizeOptionChanged: false,
        }

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldCompanySizeChange = this.handleFieldCompanySizeChange.bind(this);
        this.handleFieldCountryChange = this.handleFieldCountryChange.bind(this);
        this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
    }

    handleFieldChange(event) {
        const {
            handleButtonVisibility,
            handleChange,
            prevValues,
            values,
            setValues,
        } = this.props;

        const {
            name,
            value,
        } = event.target;

        handleChange(event);

        let valuesHasChanged = false;

        Object.keys(values).forEach(key => {
            if (values[key] != prevValues[key] && key != name) {
                valuesHasChanged = true
            };
        });

        if (value != prevValues[name]) {
            valuesHasChanged = true
        };

        if (valuesHasChanged) {
            handleButtonVisibility(true);

            this.setState({
                isValuesChanged: true,
            })
        } else {
            handleButtonVisibility((false || this.state.isCountryOptionChanged || this.state.isCompanySizeOptionChanged));

            this.setState({
                isValuesChanged: false
            })
        };

        if (name == 'phone') {
            const valuesToSet = Object.assign({}, values);
            valuesToSet.phone = value[0] ? (value[0].match(/(\d|\+)/) ? value[0] : '') : '';
            valuesToSet.phone += value.substring(1).replace(/\D/g, '');
            setValues(valuesToSet);
        };
    }

    handleFieldCompanySizeChange(newOption) {
        const {
            handleButtonVisibility,
            handleCompanySizeChange,
            prevValues,
            setFieldValue,
        } = this.props;

        if (newOption.value != prevValues['company_size']) {
            handleButtonVisibility(true);

            this.setState({
                isCompanySizeOptionChanged: true,
            })
        } else {
            handleButtonVisibility((false || this.state.isValuesChanged || this.state.isCountryOptionChanged));

            this.setState({
                isCompanySizeOptionChanged: false,
            })
        }

        setFieldValue('company_size', newOption.value);

        handleCompanySizeChange(newOption);
    }

    handleFieldCountryChange(newOption) {

        const {
            handleButtonVisibility,
            handleCountryChange,
            prevValues,
            setFieldValue,
            setErrors,
            errors,
        } = this.props;

        if (newOption.value != prevValues['country']) {
            handleButtonVisibility(true);

            this.setState({
                isCountryOptionChanged: true,
            })
        } else {
            handleButtonVisibility((false || this.state.isValuesChanged || this.state.isCompanySizeOptionChanged));

            this.setState({
                isCountryOptionChanged: false,
            })
        }

        setFieldValue('country', newOption.value);

        const errorsToSet = Object.assign({}, errors);

        (newOption && Object.keys(newOption).length) && (delete errorsToSet['country']);

        setErrors(errorsToSet);

        handleCountryChange(newOption);
    }

    handleResetButtonClick() {

        const {
            handleButtonVisibility,
            handleReset,
            prevValues,
            handleCompanySizeChange,
            handleCountryChange,
            companySizeOptions,
            countries,
        } = this.props;

        handleButtonVisibility(false);

        handleCompanySizeChange(companySizeOptions[prevValues.company_size]);
        handleCountryChange(countries.find(country => country.value == prevValues.country));

        handleReset();

        this.setState({
            isCountryOptionChanged: false,
            isCompanySizeOptionChanged: false,
        });
    }



    render() {

        const {
            handleSubmit,
            handleBlur,
            values,
            errors,
            touched,
            currentStep,
            selectedCompanySize,
            selectedCountry,
            formData,
            registrationData_1,
            registrationData_2,
            registrationData_3,
            companySizeOptions,
            countries,
            handleSubmitButtonClick,
            isButtonVisible,
            isSubmitting,
        } = this.props;



        return pug `
form.form-profile(onSubmit=${handleSubmit} noValidate autoComplete="off")
    .wrapper_form-top-profile
        ${Object.keys(registrationData_1).map((key, index) => {
            if (index === 0 || index === 1) {
                return pug`
                    FormInput(
                        key=${index}
                        item=${registrationData_1[key]}
                        name=${key}
                        value=${values[key]}
                        inputClass=${touched[key] && errors[key] && 'is-invalid'}
                        errorDescription=${touched[key] && errors[key]}
                        handleChange=${this.handleFieldChange}
                        handleBlur=${handleBlur}
                    )
                `
            }
        })}
        FormInput(
            item=${registrationData_2['company']}
            name="company"
            value=${values['company']}
            inputClass=${touched['company'] && errors['company'] && 'is-invalid'}
            errorDescription=${touched['company'] && errors['company']}
            handleChange=${this.handleFieldChange}
            handleBlur=${handleBlur}
        )
        .group_field.group_dropdown
            label.label_input Company size
            Select(
                name="company_size"
                value=${selectedCompanySize}
                clearable=${false}
                removeSelected=${true}
                searchable=${false}
                onChange=${this.handleFieldCompanySizeChange}
                options=${companySizeOptions}
            )
        ${Object.keys(registrationData_2).map((key, index) => {
            if (index === 1 || index === 2) {
                return pug`
                    FormInput(
                        key=${index}
                        item=${registrationData_2[key]}
                        name=${key}
                        value=${values[key]}
                        inputClass=${touched[key] && errors[key] && 'is-invalid'}
                        errorDescription=${touched[key] && errors[key]}
                        handleChange=${this.handleFieldChange}
                        handleBlur=${handleBlur}
                    )
                `
            }
        })}
        .group_field.group_dropdown(class=${errors['country'] && 'is-invalid'})
            label.label_input Country
            Select(
                name="country"
                value=${selectedCountry}
                clearable=${false}
                searchable=${true}
                filterOptions=${true}
                onChange=${this.handleFieldCountryChange}
                options=${countries}
                multi=${false}
                placeholder=""
                noResultsText=${fieldsErrorMessages.country}
                matchPos="start"
            )
        ${Object.keys(registrationData_3).map((key, index) =>
            pug`
                FormInput(
                    key=${index}
                    item=${registrationData_3[key]}
                    name=${key}
                    value=${values[key]}
                    inputClass=${touched[key] && errors[key] && 'is-invalid'}
                    errorDescription=${touched[key] && errors[key]}
                    handleChange=${this.handleFieldChange}
                    handleBlur=${handleBlur}
                )
            `
        )}
    .wrapper_form-bottom-profile
        FormInput(
            item=${{
                type: 'password',
                label: 'Change password',
            }}
            name=${'password'}
            value=${values['password']}
            inputClass=${touched['password'] && errors['password'] && 'is-invalid'}
            errorDescription=${touched['password'] && errors['password']}
            handleChange=${this.handleFieldChange}
            handleBlur=${handleBlur}
        )
        FormInput(
            item=${{
                type: 'email',
                label: 'Email',
            }}
            name=${'email'}
            value=${values['email']}
            inputClass=${classNames({
                'is-invalid': (touched['email'] && errors['email']),
                'is-readonly': true,
            })}
            errorDescription=${touched['email'] && errors['email']}
            handleChange=${this.handleFieldChange}
            handleBlur=${handleBlur}
            readonly=${true}
        )
        FormInput(
            item=${registrationData_1['confirm_password']}
            name=${'confirm_password'}
            value=${values['confirm_password']}
            inputClass=${touched['confirm_password'] && errors['confirm_password'] && 'is-invalid'}
            errorDescription=${touched['confirm_password'] && errors['confirm_password']}
            handleChange=${this.handleFieldChange}
            handleBlur=${handleBlur}
        )
    .wrapper_buttons-profile
        button.bttn_reset-profile(
            type="button"
            disabled=${isSubmitting || !isButtonVisible}
            class=${classNames({
                'is-invisible': !isButtonVisible,
                'is-disabled': isSubmitting
            })}
            onClick=${this.handleResetButtonClick}
        ) Cancel
        button.bttn_submit-profile(
            type="submit"
            disabled=${isSubmitting || !isButtonVisible}
            class=${classNames({
                'is-invisible': !isButtonVisible,
                'is-disabled': isSubmitting
            })}
            onClick=${handleSubmitButtonClick}
        ) Save
    `
    }
}



/*
FormInput(
    item=${{
        type: 'email',
        label: 'Billing email',
    }}
    name="billing_email"
    value=${values['billing_email']}
    inputClass=${touched['billing_email'] && errors['billing_email'] && 'is-invalid'}
    errorDescription=${touched['billing_email'] && errors['billing_email']}
    handleChange=${handleChange}
    handleBlur=${handleBlur}
)
*/
