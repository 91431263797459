import React from 'react';

import FormInput from './../common/FormElems.js';

import {
    Formik
} from 'formik';

import Select from 'react-select';

import '../../../stylesheets/scss/external/react-select.scss';

import {
    registrationData_2,
    companySizeOptions
} from '../registrationData.js';
import {
    setValueToAllKeysInObj,
    valRequired,
    valURL,
    valLetters
} from '../utils.js';

import {
    fieldsErrorMessages
} from '../notifications.js';

const formData = registrationData_2;



function FormRegistration_2({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    currentStep,
    selectedValue,
    handleSelectChange,
}) {

    if (currentStep !== 1) {
        return null;
    }

    return pug `
form.form_register-personal.tab_form(onSubmit=${handleSubmit} noValidate autoComplete="off")
    FormInput(
        item=${formData['company']}
        name=${'company'}
        value=${values['company']}
        inputClass=${touched['company'] && errors['company'] && 'is-invalid'}
        errorDescription=${touched['company'] && errors['company']}
        handleChange=${handleChange}
        handleBlur=${handleBlur}
    )
    .group_field.group_dropdown
        label.label_input Company size
        Select(
            name="company_size"
            value=${selectedValue}
            clearable=${false}
            removeSelected=${true}
            searchable=${false}
            onChange=${handleSelectChange}
            options=${companySizeOptions}
        )
    FormInput(
        item=${formData['title']}
        name=${'title'}
        value=${values['title']}
        inputClass=${touched['title'] && errors['title'] && 'is-invalid'}
        errorDescription=${touched['title'] && errors['title']}
        handleChange=${handleChange}
        handleBlur=${handleBlur}
    )
    FormInput(
        item=${formData['website']}
        name=${'website'}
        value=${values['website']}
        inputClass=${touched['website'] && errors['website'] && 'is-invalid'}
        errorDescription=${touched['website'] && errors['website']}
        handleChange=${handleChange}
        handleBlur=${handleBlur}
    )

    button.bttn_submit(type="submit") Continue
        `
}



export default class FormikRegistration_2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            selectedOption: companySizeOptions[0],
        }

        this.validate = this.validate.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validate(values) {
        const errors = Object.assign({}, this.state.errors);

        Object.keys(values).forEach(key => {

            if (key == 'company' || key == 'title') {

                valRequired(values[key]) ? (
                    valLetters(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.letters)
                ) : (errors[key] = fieldsErrorMessages.require);

            } else if (key == 'website') {

                valURL(values[key]) ?
                (delete errors[key]) :
                (errors[key] = fieldsErrorMessages.website);
            }
        });

        this.setState({
            errors: errors,
        });

        return errors
    }

    handleSelectChange(selectedOption) {
        this.setState({
            selectedOption: selectedOption,
        });
    }

    handleSubmit(values, formikBag) {
        const valuesToSave = Object.assign({}, values, {
            company_size: this.state.selectedOption.value,
        });
        this.props.saveFormValues(2, valuesToSave, formikBag);
    }

    render() {
        return pug `
Formik(render=${formikProps =>
        pug`
            FormRegistration_2(
                ...formikProps
                ...this.props
                selectedValue=${this.state.selectedOption}
                handleSelectChange=${this.handleSelectChange}
            )`
        }
        validate=${this.validate}
        onSubmit=${this.handleSubmit}
        validateOnBlur=${false}
        initialValues=${setValueToAllKeysInObj(formData, '')}

    )
`
    }
};
