import React from 'react';



export default class GlobalErrorNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.isVisible) {
            return {
                isVisible: false,
            }
        }

        return null
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isVisible: true
            });
        }, 50);
    }

    render() {
        const {
            notificationText,
            handleErrorNotificationClose
        } = this.props;

        const {
            isVisible
        } = this.state;

        return pug `
.block_notification-error(class=${isVisible ? 'is-visible' : ''})
    .wrapper_text-notification
        .icon_notification
        ${pug`.text_notification ${notificationText}` || pug`.text_notification Application mistakes sometimes happen, sorry. Please try again or contact support: #[a(href="mailto:support@ambisafe.com" target="") support@ambisafe.com]`}
    .icon_close-notification(onClick=${handleErrorNotificationClose})
        `
    }
}
