import React from 'react';

import {
    Formik
} from 'formik';

import axios from 'axios';

import endpoints from '../endpoints.js';

import {
    valRequired,
    valEmail,
    setValueToAllKeysInObj
} from '../utils.js';

import {
    globalErrorMessages,
    fieldsErrorMessages,
    autoHideNotifications,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData
} from '../notifications.js';

import {
    Link,
    withRouter
} from 'react-router-dom';

import routes from '../routes.js';

import FormInput from './../common/FormElems.js';
import GlobalErrorNotification from '../common/GlobalErrorNotification.js';

import {
    Footer
} from './Footer';

const formData = {
    email: {
        type: 'email',
        label: 'Email'
    }
};

const FormRestorePassEmail = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
}) => {

    return pug `
    form.form_restore-pass(onSubmit=${handleSubmit} noValidate autoComplete="off")
        FormInput(
            item=${formData['email']}
            name=${'email'}
            value=${values['email']}
            inputClass=${touched['email'] && errors['email'] && 'is-invalid'}
            errorDescription=${touched['email'] && errors['email']}
            handleChange=${handleChange}
            handleBlur=${handleBlur}
        )
        button.bttn_submit(
            type="submit"
            disabled=${isSubmitting}
            class=${isSubmitting && 'is-disabled'}
        ) Submit
        `
}



class RestorePass1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            globalErrorText: '',
            showConfirmMessage: false,
        }

        function setInitialStates() {
            const state = {};
            Object.keys(formInputData).forEach(key => {
                state[key] = '';
            });
            return state
        }

        this.validate = this.validate.bind(this);
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validate(values) {
        const errors = {};

        Object.keys(values).forEach(key => {
            if (key == 'email') {
                valRequired(values[key]) ? (valEmail(values[key]) ? (delete errors[key]) : (errors[key] = fieldsErrorMessages.email)) : (errors[key] = fieldsErrorMessages.require);
            }
        });

        return errors
    }

    handleSubmit(values, formikBag) {

        axios.post(endpoints.passwordReset, values)
            .then(response => {
                this.setState({
                    showConfirmMessage: true,
                });
            })
            .catch(error => {
                formikBag.setSubmitting(false);

                this.handleErrorsWhileSendingData(error, formikBag)
            });
    }


    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    render() {
        const {
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalErrorText,
        } = this.state;

        return pug `
.wrapper_page.wrapper_page_with_footer
    ${isGlobalErrorVisible &&
        pug`
            GlobalErrorNotification(
                notificationText=${globalErrorText}
                isVisible=${isGlobalErrorAnimated}
                handleErrorNotificationClose=${this.handleErrorNotificationClose}
            )
        `
    }
    header.header_main
        a(href="/").img_logo
            img(src="images/Ambisafe_logo_dark.svg" alt="logo")
        ${ !this.state.showConfirmMessage && pug`
            div
                h1.title_block Enter your email to reset the password
                p.subtitle_block We will send you the password reset link via email
            `
        }
    ${ !this.state.showConfirmMessage ? pug`
    .wrapper_forms-forgot-pass
        Formik(render=${formikProps =>
            pug`
                FormRestorePassEmail(
                    ...formikProps
                    ...this.props
                )`
            }
            validate=${this.validate}
            onSubmit=${this.handleSubmit}
            initialValues=${{email: ''}}
        )
        .wrapper_text_link-entrance
            Link(to=${routes.login})
                .text_link-entrance Log in
        ` : pug`
        .block_recovery-pass-submitted
            .icon_check
            p.text_recovery-pass-submitted We sent you the password reset link, please check your email
        `
    }
    Footer
`
    }
};

export default withRouter(RestorePass1);
