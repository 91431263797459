import React from 'react';

import axios from "axios";

import InfiniteScroll from 'react-infinite-scroller';

import DateFilter from './DateFilter.js';

import SinglePayment from './SinglePayment.js';

import {
  hidePreloader
} from '../utils.js';

import MainPageWrapper from '../common/MainElements';



export default class PageHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMore: true,
            paymentsList: [],
            loadCounter: 0, /*temporary*/
        }

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {

        axios.get('http://localhost:8080/js/data_examples/payments.json')
            .then(response => {

                const payments = response.data.payments;

                this.setState(prevState => {
                    return {
                        paymentsList: prevState.paymentsList.concat(payments),
                        hasMore: (this.state.loadCounter > 10) ? false : true,
                        loadCounter: (prevState.loadCounter + 1)
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {

        const {
            paymentsList
        } = this.state;

        return pug `
MainPageWrapper(
    preloaderClassName=${'preloader_history'}
)
    header.header_block
        h2.title_block.title_block-payment-history Payment history
    .block_content
        .table_payment-history
            TitlePaymentsTable
            InfiniteScroll(
                loadMore=${this.loadMore}
                pageStart=${0}
                threshold=${300}
                className='wrapper_rows_content'
                hasMore=${this.state.hasMore}
                loader= ${ pug`
                            .wrapper_preloader-local(key='loader')
                                .preloader`
                        }
                )
                ${ Object.keys(paymentsList).map( (item, index) =>
                    pug`
                        SinglePayment(
                            key=${index}
                            paymentData=${paymentsList[index]}
                        )`
                ) }
        `
    }
};

/*key=${paymentsList[index].id }*/

const TitlePaymentsTable = () =>
pug `
    .row_title
        .inner_wrapper_row
            .cell_date-payment-history Date
            .cell_card-payment-history Card
            .cell_status-payment-history Status
            .cell_amount-payment-history Amount
            .cell_print-payment-history
            .cell_download-payment-history
`;


const Note = () =>
pug `
    .wrapper_note-payment-history
        .content_note-payment-history
            .text_note-payment-history The first case is applicable when you are using the Ambisafe Investor's Wallet to collect the investments. The second also allows selling your asset through a number of participating cryptocurrency exchanges. It could be a benefit for your investors, who will be able to use a familiar trading website to purchase ICO tokens. We will be glad to offer you our services for communication and negotiations with many exchanges if you choose the second option.
            .bttn_pay-payment-history Pay now
`;
