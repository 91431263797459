import React from 'react';

import classNames from 'classnames/dedupe';

import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

import axios from "axios";

import {
    billing
} from '../authorization.js';

import endpoints from '../endpoints.js';

import routes from '../routes.js';

import {
    globalNotificationMessages,
    globalErrorMessages,
    autoHideNotifications,
    autoCloseNotificationDuration,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData,
} from '../notifications.js';

import {
    showPreloader,
    hidePreloader
} from '../utils.js';

import MainPageWrapper from '../common/MainElements';

import GlobalErrorNotification from '../common/GlobalErrorNotification';

import GlobalSuccessNotification from '../common/GlobalSuccessNotification';

import {
    PlaceholderDashboard,
    PlaceholderCryptoWallet,
    PlaceholderAmbisafeVault
} from './ElemsDisabledDashboard';

import TableCryptoWallet from './TableCryptoWallet';

import TableAmbisafeVault from './TableAmbisafeVault';

import TableDashboard from './TableDashboard';


const generateAmbiVaultInvoices = (invoices) => invoices.map(invoice => {
    const orgCreationItems = invoice.items.filter(item => item.service === 'organization_creation');
    const transCompleteItems = invoice.items.filter(item => item.service === 'transaction_complete');
    const allItems = orgCreationItems.concat(transCompleteItems);
    return {
        id: invoice.id,
        period_start: invoice.period_start,
        period_end: invoice.period_end,
        is_paid: invoice.is_paid,
        total_sum: sumBy(allItems, i => Number(i.total_sum)),
        operations_count: sumBy(transCompleteItems, 'operations_count'),
        operations_price: transCompleteItems.length ? meanBy(transCompleteItems, i => Number(i.operations_price)) : 0,
        subscription: sumBy(orgCreationItems, i => Number(i.operations_price)),
    }
});

const generateCryptoWalletInvoices = (invoices) => invoices.map(invoice => {
    const items = invoice.items.filter(item => item.service === 'wallet_active_user');
    return {
        id: invoice.id,
        period_start: invoice.period_start,
        period_end: invoice.period_end,
        is_paid: invoice.is_paid,
        total_sum: sumBy(items, i => Number(i.total_sum)),
        operations_count: sumBy(items, 'operations_count'),
        operations_price: items.length ? meanBy(items, i => Number(i.operations_price)) : 0
    }
});


export default class PageDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            animatedStyles: {
                tablesWrapper: {
                    height: 0
                }
            },
            globalErrorText: '',
            globalNotificationText: '',
            invoicesSummaryData: {},
            isGlobalErrorAnimated: false,
            isGlobalErrorVisible: false,
            isGlobalNotificationAnimated: false,
            isGlobalNotificationVisible: false,
            me: {},
            serviceAVIsActive: false,
            serviceCWIsActive: false,
            servicesDetailsIsVisible: false,
            tableContentAV: [],
            tableContentCW: [],
        }

        this.handleToggleDetailsButtonClick = this.handleToggleDetailsButtonClick.bind(this);
        this.handleCWRequestButtonClick = this.handleCWRequestButtonClick.bind(this);
        this.handleAVRequestButtonClick = this.handleAVRequestButtonClick.bind(this);
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
    }


    componentDidMount() {
        axios.get(endpoints.profileChange)
            .then(response => {
                this.setState({
                    me: response.data
                });
            })
            .catch(error => {
                this.handleErrorsWhileSendingData(error);
            });

        axios.get(endpoints.dashboardWallet)
            .then(response => {
                this.setState({
                    serviceCWIsActive: response.data.is_wallet_enabled
                });
            })
            .catch((error) => {
                this.handleErrorsWhileSendingData(error);
            });

        billing.get(endpoints.dashboardAmbiVault)
            .then(response => {
                this.setState({
                    serviceAVIsActive: Boolean(response.data.results.length)
                });
            })
            .catch((error) => {
                this.handleErrorsWhileSendingData(error);
            });

        billing.get(endpoints.invoices)
            .then(response => {
                if (!response.data.results.length) {
                    hidePreloader('.preloader_dashboard');
                    return false;
                }
                const invoices = response.data.results;

                const sumOfProps = (items, prop) => items.reduce((a, b) => a + parseFloat(b[prop]), 0);

                const allPaid = invoices.every(item => item.is_paid);
                let invoicesSummaryData;
                if (!allPaid) {
                    const unpaidInvoices = invoices.filter(invoice => !invoice.is_paid);
                    const totalSum = sumOfProps(unpaidInvoices, 'total_sum');
                    invoicesSummaryData = {
                        periodStart: unpaidInvoices[unpaidInvoices.length - 1].period_start,
                        periodEnd: unpaidInvoices[0].period_end,
                        allPaid: allPaid,
                        totalSum: totalSum,
                    };
                } else {
                    const totalSum = sumOfProps(invoices, 'total_sum');
                    invoicesSummaryData = {
                        periodStart: invoices[invoices.length - 1].period_start,
                        periodEnd: invoices[0].period_end,
                        allPaid: allPaid,
                        totalSum: totalSum,
                    };
                }

                this.setState({
                    invoicesSummaryData: invoicesSummaryData,
                    tableContentCW: generateCryptoWalletInvoices(invoices),
                    tableContentAV: generateAmbiVaultInvoices(invoices),
                });
                hidePreloader('.preloader_dashboard');
            })
            .catch(error => {
                hidePreloader('.preloader_dashboard');
                this.handleErrorsWhileSendingData(error);
            });
    }

    handleToggleDetailsButtonClick() {
        const {
            servicesDetailsIsVisible
        } = this.state;

        const animatedStyles = Object.assign({}, this.state.animatedStyles);
        const tablesWrapperStyles = Object.assign({}, this.state.animatedStyles.tablesWrapper);

        if (this.tablesWrapper) {
            tablesWrapperStyles.height = !servicesDetailsIsVisible ? this.tablesWrapper.offsetHeight : 0
        }

        animatedStyles.tablesWrapper = tablesWrapperStyles;

        this.setState(prevState => {
            return {
                animatedStyles,
                servicesDetailsIsVisible: !prevState.servicesDetailsIsVisible,
            }
        })
    }

    handleCWRequestButtonClick() {
        window.Intercom('showNewMessage', 'Hi Ambisafe team!\nI’m interested in setting up my CryptoWallet.\nHere are my requirements:\n');
    }

    handleAVRequestButtonClick() {
        const {
            me
        } = this.state;
        showPreloader('.preloader_inner-page');
        axios.get(endpoints.menuAmbiVault)
            .then(response => {
                self.location = response.data.redirect_url;
            }).catch(error => {
                if (error.response.status === 409) {
                    this.props.history.push(routes.paymentMethods);
                } else {
                    this.handleErrorsWhileSendingData(error);
                    hidePreloader('.preloader_inner-page');
                }
            });
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    render() {

        const {
            animatedStyles,
            globalErrorText,
            globalNotificationText,
            invoicesSummaryData,
            isGlobalErrorAnimated,
            isGlobalErrorVisible,
            isGlobalNotificationAnimated,
            isGlobalNotificationVisible,
            me,
            serviceAVIsActive,
            serviceCWIsActive,
            servicesDetailsIsVisible,
            tableContentAV,
            tableContentCW,
        } = this.state;

        const anyServiceIsActive = (serviceAVIsActive || serviceCWIsActive);
        const anyServiceHasInvoices = (tableContentAV.length !== 0 || tableContentCW.length !== 0);
        const allServicesHasInvoices = (tableContentAV.length !== 0 && tableContentCW.length !== 0);
        const placeholderNoInvoices = pug`p We updated the software and implemented a new billing method. For this reason, the billing numbers are temporarily unavailable. #[br] #[br] You will see the stats per CryptoWallet and/or AmbiVault on the first day of the month. Thank you for understanding.`;


        return pug `
MainPageWrapper(
    preloaderClassName=${'preloader_dashboard'}
)
        ${isGlobalErrorVisible &&
            pug`
                GlobalErrorNotification(
                    notificationText=${globalErrorText}
                    isVisible=${isGlobalErrorAnimated}
                    handleErrorNotificationClose=${this.handleErrorNotificationClose}
                )
            `
        }
        ${isGlobalNotificationVisible &&
            pug`
                GlobalSuccessNotification(
                    notificationText=${globalNotificationText}
                    isVisible=${isGlobalNotificationAnimated}
                )
            `
        }
        header.header_block
            h2.title_block Dashboard
        .block_content-dashboard
            ${anyServiceHasInvoices ? pug`
                TableDashboard(
                    handleClick=${this.handleToggleDetailsButtonClick}
                    servicesDetailsIsVisible=${servicesDetailsIsVisible}
                    invoicesSummaryData=${invoicesSummaryData}
                )` : (anyServiceIsActive ? pug`
                PlaceholderDashboard(
                    placeholderText=${placeholderNoInvoices}
                )` : pug`
                PlaceholderDashboard(
                    placeholderText="No services available. Add them here:"
                )`)
            }
        ${anyServiceHasInvoices ?
            pug`
                .wrapper_content-animated-dashboard(
                    class=${servicesDetailsIsVisible ? 'is-visible' : 'is-hidden'}
                    style=${animatedStyles.tablesWrapper}
                )
                    .wrapper_services-enabled(ref=${tablesWrapper => this.tablesWrapper = tablesWrapper})
                        ${serviceCWIsActive && tableContentCW.length !== 0 && pug`TableCryptoWallet(items=${tableContentCW})`}
                        ${serviceAVIsActive && tableContentAV.length !== 0 && pug`TableAmbisafeVault(items=${tableContentAV})`}
                        .button_toggle-details(
                            class=${servicesDetailsIsVisible ? 'is-visible' : 'is-hidden'} onClick=${this.handleToggleDetailsButtonClick}
                        ) Hide details
            ` : '' }

        .wrapper_services-disabled
            ${!serviceCWIsActive &&
                pug`PlaceholderCryptoWallet(handleRequestButtonClick=${this.handleCWRequestButtonClick})`}
            ${!serviceAVIsActive &&
                pug`PlaceholderAmbisafeVault(
                    handleRequestButtonClick=${this.handleAVRequestButtonClick}
                    me=${me}
                )`}

                `
    }
}
