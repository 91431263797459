const routes = {
    login: '/entrance',
    registration: '/signup',
    dashboard: '/dashboard',
    profile: '/profile',
    paymentMethods: '/cards',
    paymentHistory: '/history',
    restorePassEmail: '/restore-password-reset',
    restorePassPassword: '/restore-password-confirmation/',
    emailConfirmation: '/email-confirmation/',
}

export default routes;
