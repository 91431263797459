import axios from 'axios';

import React from 'react';

import FormCardData from './FormCardData';

import {
    Elements
} from 'react-stripe-elements';

import ModalWindow from '../common/ModalWindow.js';

import endpoints from '../endpoints.js';

import {
    billing
} from '../authorization.js';

import Card from './Card.js';

import {
    globalErrorMessages,
    autoHideNotifications,
    autoCloseNotificationDuration,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData,
} from '../notifications.js';

import {
  hidePreloader
} from '../utils.js';

import MainPageWrapper from '../common/MainElements';

import GlobalErrorNotification from '../common/GlobalErrorNotification.js';
import GlobalSuccessNotification from '../common/GlobalSuccessNotification.js';

const AddCardPlaceholder = ({
    toogleAddCardModal
}) => pug `
    .wrapper_item-card
        .item_add-card(onClick=${toogleAddCardModal})
            .text_add-card Add payment method
`

const AddCardModal = ({
    me,
    addCard,
    clearCurrentPrimaryCard,
    atLeastOneCardExists,
    showGlobalError,
    showGlobalNotification,
    handleErrorsWhileSendingData,
}) => {
    const stripeFonts = [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
    }];
    return pug `
        div
            header.header_modal
                h4.title_block New payment method
            .wrapper_card-modal-form
                Elements(fonts=${stripeFonts})
                    FormCardData(
                        me=${me}
                        addCard=${addCard}
                        clearCurrentPrimaryCard=${clearCurrentPrimaryCard}
                        atLeastOneCardExists=${atLeastOneCardExists}
                        showGlobalError=${showGlobalError}
                        showGlobalNotification=${showGlobalNotification}
                        handleErrorsWhileSendingData=${handleErrorsWhileSendingData}
                    )
    `
};

export default class PaymentMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            me: {},
            addCardModalActive: false,
            cards: [],
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            isGlobalNotificationVisible: false,
            isGlobalNotificationAnimated: false,
            globalNotificationText: '',
            globalErrorText: '',
        }

        this.toogleAddCardModal = this.toogleAddCardModal.bind(this);
        this.appendCard = this.appendCard.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.setPrimary = this.setPrimary.bind(this);
        this.clearCurrentPrimaryCard = this.clearCurrentPrimaryCard.bind(this);

        this.modalTransition = 300;
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.showGlobalNotification = this.showGlobalNotification.bind(this);
        this.showGlobalError = this.showGlobalError.bind(this);
    }

    componentDidMount() {
        axios.get(endpoints.profileGet)
            .then(response => {
                const me = response.data;
                this.setState({
                    me
                });
            })
            .catch(error => {
                this.handleErrorsWhileSendingData(error);
            });
        billing.get(endpoints.stripeCards)
            .then(response => {
                this.setState({
                    cards: response.data
                });

                hidePreloader('.preloader_cards');
            })
            .catch(error => {
                this.handleErrorsWhileSendingData(error);
            });
    }

    appendCard(card) {
        this.setState(prevState => ({
            cards: [...prevState.cards, card]
        }))
        this.toogleAddCardModal();
    }

    toogleAddCardModal() {
        this.setState(prevState => ({
            addCardModalActive: !prevState.addCardModalActive,
        }))
    }

    setPrimary(cardId) {
        this.clearCurrentPrimaryCard();
        this.setState({
            cards: this.state.cards.map(card => (card.id === cardId ? Object.assign({}, card,
                {is_default: true}) : card))
        });

    }

    clearCurrentPrimaryCard() {
        this.setState({
            cards: this.state.cards.map(card => (card.is_default === true ? Object.assign({}, card,
                {is_default: false}) : card))
        });
    }

    removeCard(id) {
        this.setState(prevState => ({
            cards: prevState.cards.filter(card => card.id !== id)
        }));
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    showGlobalNotification(message) {

        this.setState({
            isGlobalNotificationVisible: true,
            isGlobalNotificationAnimated: true,
            globalNotificationText: message,
        });

        this.autoHideNotifications('isGlobalNotificationVisible', autoCloseNotificationDuration, 'isGlobalNotificationAnimated');
    }

    showGlobalError(message) {

        this.setState({
            isGlobalErrorVisible: true,
            isGlobalErrorAnimated: true,
            globalErrorText: message,
        });

        this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
    }


    render() {
        const {
            me,
            cards,
            addCardModalActive,
            globalErrorText,
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalNotificationText,
            isGlobalNotificationVisible,
            isGlobalNotificationAnimated,
        } = this.state;
        const atLeastOneCardExists = Boolean(cards.length);

        return pug`
            MainPageWrapper(
                preloaderClassName=${'preloader_cards'}
            )
                ${isGlobalErrorVisible &&
                    pug`
                        GlobalErrorNotification(
                            notificationText=${globalErrorText}
                            isVisible=${isGlobalErrorAnimated}
                            handleErrorNotificationClose=${this.handleErrorNotificationClose}
                        )
                    `
                }
                ${isGlobalNotificationVisible &&
                    pug`
                        GlobalSuccessNotification(
                            notificationText=${globalNotificationText}
                            isVisible=${isGlobalNotificationAnimated}
                        )
                    `
                }
                header.header_block
                    h2.title_block Manage your payment method
                .wrapper_cards
                    .inner_wrapper_cards
                        ${cards.map(card =>
                            card.is_default && pug`
                                    Card(
                                        key=${card.id}
                                        card=${card}
                                        removeCard=${this.removeCard}
                                        setPrimary=${this.setPrimary}
                                        clearCurrentPrimaryCard=${this.clearCurrentPrimaryCard}
                                        showGlobalNotification=${this.showGlobalNotification}
                                        handleErrorsWhileSendingData=${this.handleErrorsWhileSendingData}
                                    )`
                        )}
                        ${cards.map(card =>
                            !card.is_default && pug`
                                    Card(
                                        key=${card.id}
                                        card=${card}
                                        removeCard=${this.removeCard}
                                        setPrimary=${this.setPrimary}
                                        clearCurrentPrimaryCard=${this.clearCurrentPrimaryCard}
                                        showGlobalNotification=${this.showGlobalNotification}
                                        handleErrorsWhileSendingData=${this.handleErrorsWhileSendingData}
                                    )`
                        )}
                        AddCardPlaceholder(
                            toogleAddCardModal=${this.toogleAddCardModal}
                        )
                .modal_wrapper
                    ModalWindow(
                        modalClass="modal_card"
                        active=${addCardModalActive}
                        closeModal=${this.toogleAddCardModal}
                        modalTransition=${this.modalTransition}
                    )
                        AddCardModal(
                            me=${me}
                            addCard=${this.appendCard}
                            clearCurrentPrimaryCard=${this.clearCurrentPrimaryCard}
                            atLeastOneCardExists=${atLeastOneCardExists}
                            showGlobalError=${this.showGlobalError}
                            showGlobalNotification=${this.showGlobalNotification}
                            handleErrorsWhileSendingData=${this.handleErrorsWhileSendingData}
                        )
        `
    }
}
