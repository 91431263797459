import React from 'react';

import {
    Redirect,
    withRouter
} from 'react-router';

import {
    Formik
} from 'formik';

import axios from 'axios';

import ReCAPTCHA from 'react-google-recaptcha';

import Raven from 'raven-js';

import {
    userAuth
} from '../authorization.js';

import {
    globalNotificationMessages,
    globalErrorMessages,
    fieldsErrorMessages,
    autoHideNotifications,
    autoCloseNotificationDuration,
    autoCloseErrorDuration,
    handleErrorsWhileSendingData,
} from '../notifications.js';

import endpoints from '../endpoints.js';

import routes from '../routes.js';

import FormInput from './../common/FormElems.js';
import GlobalErrorNotification from '../common/GlobalErrorNotification.js';

import GlobalSuccessNotification from '../common/GlobalSuccessNotification.js';

import {
    valRequired,
    valEmail
} from '../utils.js';

const formInputData = {
    email: {
        type: 'email',
        label: 'Email',
    },
    password: {
        inputClassName: 'field_password',
        type: "password",
        label: "Password",
    }
};



const FormLogin = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    recaptchaSiteKey,
    recaptchaVerifedCallback,
    recaptchaExpiredCallback,
    isSubmitting,
}) => {

    return pug `
            form.form_login(onSubmit=${handleSubmit} noValidate autoComplete="off")
                ${Object.keys(formInputData).map((key, index) =>
                    pug`
                        FormInput(
                            key=${index}
                            name=${key}
                            item=${formInputData[key]}
                            value=${values[key]}
                            inputClass=${touched[key] && errors[key] && 'is-invalid'}
                            errorDescription=${touched[key] && errors[key]}
                            handleChange=${handleChange}
                            handleBlur=${handleBlur}
                        )
                    `
                )}
                .wrapper_captcha
                    .inner_wrapper_captcha
                        ReCAPTCHA(
                            sitekey=${recaptchaSiteKey}
                            onChange=${recaptchaVerifedCallback}
                            onExpired=${recaptchaExpiredCallback}
                        )
                button.bttn_submit(
                    type="submit"
                    disabled=${isSubmitting}
                    class=${isSubmitting && 'is-disabled'}
                ) Log in
        `
}



class FormikLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isGlobalErrorVisible: false,
            isGlobalErrorAnimated: false,
            isGlobalNotificationVisible: false,
            isGlobalNotificationAnimated: false,
            globalNotificationText: '',
            globalErrorText: '',
            redirectToReferrer: false,
        }

        this.validate = this.validate.bind(this);
        this.recaptchaVerifedCallback = this.recaptchaVerifedCallback.bind(this);
        this.recaptchaExpiredCallback = this.recaptchaExpiredCallback.bind(this);
        this.handleErrorNotificationClose = this.handleErrorNotificationClose.bind(this);
        this.autoHideNotifications = autoHideNotifications.bind(this);
        this.handleErrorsWhileSendingData = handleErrorsWhileSendingData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        try {
            if (localStorage.getItem('successfulRegistration')) {

                localStorage.removeItem('successfulRegistration');

                this.setState({
                    isGlobalNotificationVisible: true,
                    isGlobalNotificationAnimated: true,
                    globalNotificationText: globalNotificationMessages.successfulRegistration,
                });

                this.autoHideNotifications('isGlobalNotificationVisible', autoCloseNotificationDuration, 'isGlobalNotificationAnimated');
            } else if (localStorage.getItem('successfulPasswordChange')) {

                localStorage.removeItem('successfulPasswordChange');

                this.setState({
                    isGlobalNotificationVisible: true,
                    isGlobalNotificationAnimated: true,
                    globalNotificationText: globalNotificationMessages.successfulPasswordChange,
                });

                this.autoHideNotifications('isGlobalNotificationVisible', autoCloseNotificationDuration, 'isGlobalNotificationAnimated');
            } else if (localStorage.getItem('successfulEmailConfirmation')) {

                localStorage.removeItem('successfulEmailConfirmation');

                this.setState({
                    isGlobalNotificationVisible: true,
                    isGlobalNotificationAnimated: true,
                    globalNotificationText: globalNotificationMessages.successfulConfirmEmail,
                });

                this.autoHideNotifications('isGlobalNotificationVisible', autoCloseNotificationDuration, 'isGlobalNotificationAnimated');
            } else if (localStorage.getItem('errorEmailConfirmation')) {

                localStorage.removeItem('errorEmailConfirmation');

                this.setState({
                    isGlobalErrorVisible: true,
                    isGlobalErrorAnimated: true,
                    globalErrorText: globalErrorMessages.emailConfirmationTokenIsWrong,
                });

                this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
            }
        } catch (err) {}
    }

    validate(values) {
        const errors = {};

        Object.keys(values).forEach(key => {
            if (key == 'email') {
                valRequired(values[key]) ? (
                    valEmail(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.email)
                ) : (errors[key] = fieldsErrorMessages.require);
            } else if (key == 'password') {
                valRequired(values[key]) ?
                    (delete errors[key]) :
                    (errors[key] = fieldsErrorMessages.require);
            }
        });

        return errors
    }

    recaptchaVerifedCallback() {
        this.setState({
            reCAPTCHAVerified: true,
        });
    }

    recaptchaExpiredCallback() {
        this.setState({
            reCAPTCHAVerified: false,
        });
    }

    handleErrorNotificationClose() {
        this.autoHideNotifications('isGlobalErrorVisible', 0, 'isGlobalErrorAnimated');
    }

    handleSubmit(values, formikBag) {

        let errorMessage;

        if (this.state.reCAPTCHAVerified) /*(true)*/ {
            axios.post(endpoints.login, values)
                .then(response => {
                    userAuth.authenticate(response.data.token, () => {
                        this.setState({
                            redirectToReferrer: true,
                        });
                    });
                    if (process.env.USE_SENTRY === true) {
                        Raven.setUserContext(response.data.user);
                    }
                    //this.props.history.push(routes.dashboard);
                })
                .catch(error => {
                    formikBag.setSubmitting(false);

                    this.handleErrorsWhileSendingData(error, formikBag, function() {
                        if (error.response.status == 400) {
                            //Login or password are wrong

                            formikBag.setFieldError('email', fieldsErrorMessages.invalidValues);
                            formikBag.setFieldError('password', fieldsErrorMessages.invalidValues);

                            return globalErrorMessages.invalidValues
                        }
                    })
                });
        } else {

            formikBag.setSubmitting(false);

            // Show general error message
            if (!this.state.reCAPTCHAVerified) {
                errorMessage = globalErrorMessages.captcha;
            }

            this.setState({
                isGlobalErrorVisible: true,
                isGlobalErrorAnimated: true,
                globalErrorText: errorMessage,
            });

            this.autoHideNotifications('isGlobalErrorVisible', autoCloseErrorDuration, 'isGlobalErrorAnimated');
        }
    }

    render() {
        const {
            redirectToReferrer,
            globalErrorText,
            isGlobalErrorVisible,
            isGlobalErrorAnimated,
            globalNotificationText,
            isGlobalNotificationVisible,
            isGlobalNotificationAnimated,
        } = this.state;

        if (redirectToReferrer) {
            return pug `Redirect(push to=${routes.dashboard})`;
        }

        return pug `
div
    ${isGlobalErrorVisible &&
        pug`
            GlobalErrorNotification(
                notificationText=${globalErrorText}
                isVisible=${isGlobalErrorAnimated}
                handleErrorNotificationClose=${this.handleErrorNotificationClose}
            )
        `
    }
    ${isGlobalNotificationVisible &&
        pug`
            GlobalSuccessNotification(
                notificationText=${globalNotificationText}
                isVisible=${isGlobalNotificationAnimated}
            )
        `
    }
    Formik(render=${formikProps =>
        pug`
            FormLogin(
                ...formikProps
                ...this.props
                recaptchaVerifedCallback=${this.recaptchaVerifedCallback}
                recaptchaExpiredCallback=${this.recaptchaExpiredCallback}
            )`
        }
        validate=${this.validate}
        onSubmit=${this.handleSubmit}
        validateOnBlur=${false}
        initialValues=${{email: '', password: ''}}
    )
`
    }
};



export default withRouter(FormikLogin);
